// Import libraries
import CreateDisplayDate from './CreateDisplayDate.js';
import _ from "lodash";

// Import static data

// Import CSS, images & icons



// *********************** MAIN FUNCTION *************************************** 
export default function CreateOpenFlightsList(flightList, emptySubmission) {
    let displayFlightList = [];
    let i = 0;
    var nextFlight = {...emptySubmission};
        // console.log("In CreateOpenFlightList, in-loop, emptySubmission->", emptySubmission)
  
    if (flightList) {
        flightList.forEach((flight) => {    
                // console.log("In CreateOpenFlightList, in-loop, flight->", flight)
            // Initialise and reset the nextFlight container
            nextFlight = _.cloneDeep(emptySubmission.data);
            // Object.keys(emptySubmission.data).forEach((field) => {
            //     nextFlight[field] = {...emptySubmission.data[field]};
            // })
                // console.log("In CreateOpenFlightList, in-loop, nextFlight->", nextFlight)
            // *************************** REFORMAT DATE FOR MOBILE AND NAVIGATOR *********************************
            if (flight !== undefined) {
                flight.dateOpened = CreateDisplayDate(flight.dateOpened);
            }
            nextFlight.registration.value = flight.registration;   
            nextFlight.id.value = flight.id;
            nextFlight.dateOfFlight.value = flight.dateOfFlight;
            nextFlight.dateOpened.value = flight.dateOpened;
            nextFlight.status.value = "OPEN";
            nextFlight.pilot.value = flight.pilot;
            nextFlight.passenger1.value = flight.passenger1;
            nextFlight.passenger2.value = flight.passenger2;
            //  Departure Fuel and Oil (8)
            nextFlight.departureFuelUpliftLeft.value = flight.departureFuelUpliftLeft;
            nextFlight.departureFuelUpliftRight.value = flight.departureFuelUpliftRight;
            nextFlight.departureFuelLeft.value = flight.departureFuelLeft;
            nextFlight.departureFuelRight.value = flight.departureFuelRight;
            nextFlight.oilUpliftLeft.value = flight.oilUpliftLeft;
            nextFlight.oilUpliftRight.value = flight.oilUpliftRight;
            nextFlight.oilStatusLeft.value = flight.oilStatusLeft;
            nextFlight.oilStatusRight.value = flight.oilStatusRight;
            //Times
            nextFlight.svcOff.value = flight.svcOff;
            // Set all null fields to an empty string
            Object.keys(emptySubmission.data).forEach((field) => {
            if (nextFlight[field].value === null) {
                nextFlight[field].value = "";
            }
            })
                // console.log("In CreateOpenFlightList, in-loop nextFlight.dateOpened.value->", nextFlight.dateOpened.value);
            displayFlightList[i] = {...nextFlight};
            i++;
            // displayFlightList = [...displayFlightList, nextFlight];
                // console.log("In CreateOpenFlight, nextFlight->",nextFlight);
                // console.log("In CreateOpenFlight, displayFlightList->",displayFlightList);
        })
    }
    return displayFlightList;
}