// Import libraries
import * as React from 'react';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import { pilotLogColumns}  from "./PilotLogColumns.js"
import { CreatePilotLogRow } from './CreatePilotLogRow.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMediaQuery } from '@mui/material';
import { GetFlightListPilot } from '../../utilities/database/GetFlightListPilot.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { GetRoles} from '../../utilities/database/GetRoles.js';
import { useAuthFirebase } from '../../utilities/security/AuthContextFirebase.js';
import { GetUserRoles } from '../../utilities/security/AuthUtilities.js';
// import { CreatePilotList } from '../../aircraft/CreateAircraftList.js';

// Import CSS, images, icons & static data
import { rolesTemplate } from '../../utilities/database/GetRolePermissions.js';
import { mobileThreshold } from '../../utilities/environment/EnvironmentData.js';
import { PilotLogBanner } from './PilotLogBanner.js';
import { columnGroups } from './PilotLogColumnGroupingModel.js';
import { PilotLogSummaryRows } from './PilotLogSummaryRows.js';
import { GetPilotLogSummaryData } from '../../utilities/database/GetPilotLogSummaryData.js';
// import { permissionedRole } from '../../utilities/database/RoleNames.js';


// ******************************* MAIN FUNCTION ************************************************
function PilotLog(props, {children}) {
  // Initialise context using default variables
  const title = "Pilot Log";
  const [pilotList, setPilotList] = useState([]);
  const [selectedPilot, setSelectedPilot] = useState("");
  const [pilotForDisplay, setPilotForDisplay] = useState("");
  const [flightList, setFlightList] = useState([]);
  const [databaseConnection, setDatabaseConnection] = useState(true);
  const columnList = pilotLogColumns;
  dayjs.extend(utc);


  // ***************** SECURITY - CURRENT UER CONTEXT AND ROLE **********************
  const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
  const [currentUser, setCurrentUser] = useState("")
  const [currentUserRoles, setCurrentUserRoles] = useState(rolesTemplate)
  const [pilotLogSummary, setPilotLogSummary] = useState({})
  useEffect (() => {
      authUser ? setCurrentUser(authUser.displayName) : setCurrentUser("")
  }, [authUser]); 

  const UpdateRoles = async () => {
    let responseObject = await GetUserRoles();
    setCurrentUserRoles(responseObject.data);
    setDatabaseConnection(responseObject.databaseConnection);
  }

    
  useEffect(() => {
    if (currentUser !== "") {
      UpdateRoles();
    }
  }, [currentUser])

      // console.log("In PilotLog, currentUserRoles->", currentUserRoles)

  // ***********************************************************************

// ***************** UPDATE FUNCTIONS FOR CALLED COMPONENTS **************
  function UpdateSelectedPilot(e) {
      // console.log("In PilotSelect, UpdateSelectedPilot, e->", e.target.textContent)
    setSelectedPilot(e.target.textContent)
    if (e.target.textContent) {
      setPilotForDisplay(e.target.textContent)
        // console.log("In UpdateSelectedPilot, e.target.value->", e.target.value)
      BuildFlightList(e.target.textContent)
    } else {
      setFlightList([]);
    }
    }

    function UpdateDatabaseConnection (response) {
      setDatabaseConnection(response);
    }
  // ***********************************************************************//

  const BuildFlightList = async (pilotForDisplay) => {
      // console.log("In BuildFlightList, pilotForDisplay->", pilotForDisplay)
    if (pilotForDisplay) {
      let responseObject = await GetFlightListPilot(pilotForDisplay);
      let responseObjectSummary = await PilotLogSummaryRows(pilotForDisplay)
      setPilotLogSummary(responseObjectSummary.data);
      setFlightList(responseObject.data);
        console.log("In BuildFlightList, responseObjectSummary->", responseObjectSummary)
      if (responseObject.databaseConnection && responseObjectSummary.databaseConnection) {
        UpdateDatabaseConnection(true);
      } else {
        UpdateDatabaseConnection(false);
      }
    } else {
      setFlightList([]);
      setSelectedPilot(currentUser);
    }
  }



  useEffect(() => {
    if (currentUser !== null && currentUser !== undefined && currentUser !== "") {
        // console.log("In PilotLog, currentUserRoles->", currentUserRoles)
      if (currentUserRoles.admin.status || currentUserRoles.schoolAdmin.status) {
        if (selectedPilot !== null && selectedPilot !== undefined && selectedPilot !== "") {
            // console.log("In PilotLog, selectedPilot->", selectedPilot)
            setPilotForDisplay(selectedPilot); 
            BuildFlightList(selectedPilot);
              // console.log("In PilotLog, pilotLogSummary->", pilotLogSummary)
        } else {
          setPilotForDisplay(currentUser); 
          BuildFlightList(currentUser);
            // console.log("In PilotLog, selectedPilot->", selectedPilot, "flightList->", flightList);
        }
      } else {  
        setPilotForDisplay(currentUser); 
        BuildFlightList(currentUser);
      }
    }

      // console.log("In PilotLog, selectedPilot->", selectedPilot, "currentUserRoles.admin.status->", currentUserRoles.admin.status)
  }, [currentUser, selectedPilot, pilotForDisplay]);



//*********************************** DEFINE TABLE *******************************************************

const other = {
  autoHeight: true,
  // showCellVerticalBorder: true,
  showColumnVerticalBorder: true,
};

// const columns = [];
  
let rows = [];
if (flightList) {
  flightList.forEach((flight) => {
    rows.push(CreatePilotLogRow(pilotForDisplay, flight));
  })
}


var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
var pilotLogToolbar = {toolbar: GridToolbar}

if (media) {
    pilotLogToolbar = {toolbar: GridToolbar};
  } else {
    pilotLogToolbar = {};
  }

    // console.log("In PilotLog, pilotLogSummary->", pilotLogSummary,"pilotForDisplay->", pilotForDisplay, "currentUserRoles->", currentUserRoles, "selectedPilot->", selectedPilot)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <Box>
          <PilotLogBanner className = "banner-admin" 
            title = {title} 
            UpdateDatabaseConnection = {UpdateDatabaseConnection}
            databaseConnection = {databaseConnection}
            pilotForDisplay = {pilotForDisplay}
            selectedPilot = {selectedPilot}
            pilotLogSummary = {pilotLogSummary}
            UpdateSelectedPilot = {UpdateSelectedPilot}
            calledFrom = "PilotLog"/>
      </Box>
      <Box sx = {{minWidth: "370px"}}>  
        <Box> 
          <DataGrid
            color = "info"
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
              columns: {
                columnVisibilityModel: {
                  // Hide column 'id', the other columns will remain visible
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              }
            }}
            rows={rows} // Dummy rows to allow columns to appear frozen at the top of the page
            columns={columnList}
            
            slots = {pilotLogToolbar}
            experimentalFeatures = {{ columnGrouping: true }}
            columnGroupingModel = {columnGroups}
            columnGrouping = {columnGroups}
            pageSizeOptions={[100]}
            // checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> {/* END DATA GRID HEADER */}
      </Box>
        
    </LocalizationProvider>
  );
};

export default PilotLog;