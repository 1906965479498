//Import libraries
import moment from "moment";
import dayjs from "dayjs";
var decimalAdjust = require('decimal-adjust');

// ************************************************ FLIGHT OBJECTS *************************************************

// Flight Details (53) 
export default class Flight {
    constructor (
        // Base details (3)
        registration, 
        status,
        dateOfFlight,
        dateOpened,
        pilot,
        // Departure details (10)
        passenger1,
        passenger2,
        departureFuelUpliftLeft,
        departureFuelUpliftRight,
        departureFuelLeft, 
        departureFuelRight, 
        oilUpliftLeft,
        oilUpliftRight,
        oilStatusLeft,
        oilStatusRight, 
         // Arrival details (4)
        from, 
        to, 
        flightDetails, 
        homeLandings,
        awayLandings,
        nightLandings,
        // Arrival Fuel (4 + 1)
        arrivalFuelUpliftLeft,
        arrivalFuelUpliftRight,
        arrivalFuelLeft, 
        arrivalFuelRight,  
        // fuelUsed,
            // Fuel Used
        // Flight times (6 + 3)
        offBlocks,
        onBlocks,
        //blockDifference,
            // blockTime
        takeOff,
        landing,
        //flightTime,
            // flightTime
        svcOff,
        svcOn,
        //svcDifference,
            // SVC Difference
        //Defects (2)
        newDefect1,
        newDefect2,
        // Away landings (20)
        awayLandingLocation1,
        awayLandingQuantity1,
        awayLandingDetails1,
        awayLandingsPaid1,
        awayLandingLocation2,
        awayLandingQuantity2,
        awayLandingDetails2,
        awayLandingsPaid2,
        awayLandingLocation3,
        awayLandingQuantity3,
        awayLandingDetails3,
        awayLandingsPaid3,
        awayLandingLocation4,
        awayLandingQuantity4,
        awayLandingDetails4,
        awayLandingsPaid4,
        awayLandingLocation5,
        awayLandingQuantity5,
        awayLandingDetails5,
        awayLandingsPaid5,
        ) {
    
    // Base details (3)
    this._registration = registration;
    this._status = status;
    this._dateOfFlight = dateOfFlight;
    this._dateOpened = dateOpened;
    this._pilot = pilot;
    
    // Departure details (10)
    this._passenger1 = passenger1;
    this._passenger2 = passenger2;
    this._departureFuelUpliftLeft = departureFuelUpliftLeft;
    this._departureFuelUpliftRight = departureFuelUpliftRight;
    this._departureFuelLeft = departureFuelLeft;
    this._departureFuelRight = departureFuelRight;
    this._oilUpliftLeft = oilUpliftLeft;
    this._oilUpliftRight = oilUpliftRight;
    this._oilStatusLeft = oilStatusLeft;
    this._oilStatusRight = oilStatusRight;
    
    // Arrival details (4)
    this._fromAirfield = from;
    this._toAirfield = to;
    this._flightDetails = flightDetails;
    this._homeLandings = homeLandings;
    this._awayLandings = awayLandings;
    this._nightLandings = nightLandings;

    // Arrival Fuel (5)
    this._arrivalFuelUpliftLeft = arrivalFuelUpliftLeft;
    this._arrivalFuelUpliftRight = arrivalFuelUpliftRight;
    this._arrivalFuelLeft = arrivalFuelLeft;
    this._arrivalFuelRight = arrivalFuelRight;
    this._fuelUsed = fuelUsed(this._departureFuelLeft, this._departureFuelRight, this._arrivalFuelUpliftLeft, this._arrivalFuelUpliftRight, this._arrivalFuelLeft,this._arrivalFuelRight);

    // Flight times ( 6 + 3)
    this._offBlocks = offBlocks;
    this._onBlocks = onBlocks;
    this._blockTime = calculatedBlockTime(this._offBlocks, this._onBlocks);
    this._takeOff = takeOff;
    this._landing = landing;
    this._flightTime = calculatedFlightTime(this._takeOff, this._landing);
    this._svcOff = svcOff;
    this._svcOn = svcOn;
    this._svcDifference = svcDifference(this._svcOff,this._svcOn);
    this._Instrument = 0;
    this._night = 0;

    // Defects (2)
    this._newDefect1 = newDefect1;
    this._newDefect2 = newDefect2;

    // Away landings (20)
    this._awayLandingLocation1 = awayLandingLocation1;
    this._awayLandingQuantity1 = awayLandingQuantity1;
    this._awayLandingDetails1 = awayLandingDetails1;
    this._awayLandingPaid1 = awayLandingsPaid1;
    this._awayLandingLocation2 = awayLandingLocation2;
    this._awayLandingQuantity2 = awayLandingQuantity2;
    this._awayLandingDetails2 = awayLandingDetails2;
    this._awayLandingPaid2 = awayLandingsPaid2;
    this._awayLandingLocation3 = awayLandingLocation3;
    this._awayLandingQuantity3 = awayLandingQuantity3;
    this._awayLandingDetails3 = awayLandingDetails3;
    this._awayLandingPaid3 = awayLandingsPaid3;
    this._awayLandingLocation4 = awayLandingLocation4;
    this._awayLandingQuantity4 = awayLandingQuantity4;
    this._awayLandingDetails4 = awayLandingDetails4
    this._awayLandingPaid4 = awayLandingsPaid4;
    this._awayLandingLocation5 =  awayLandingLocation5;
    this._awayLandingQuantity5 = awayLandingQuantity5;
    this._awayLandingDetails5 = awayLandingDetails5;
    this._awayLandingPaid5 = awayLandingsPaid5;
}
};

const calculatedBlockTime = (offBlock, onBlock) => {
    // return ((onBlock-offBlock)/3600000);
    return decimalAdjust("floor",((onBlock-offBlock)/3600000), -1);
};

const calculatedFlightTime = (takeOff, landing) => {
    return decimalAdjust("floor",(landing - takeOff)/3600000, -1);
};

const svcDifference = (svcOff, svcOn) => {
    return (svcOn - svcOff);
};

const fuelUsed = (departureFuelLeft, departureFuelRight, arrivalFuelUpliftLeft, arrivalFuelUpliftRight, arrivalFuelLeft, arrivalFuelRight) => {
    return (
        (departureFuelLeft + departureFuelRight + arrivalFuelUpliftLeft + arrivalFuelUpliftRight) - (arrivalFuelLeft + arrivalFuelRight)
    );
};

// Mandatory Flight Fields (53)
let flightMandatoryFields = {
    // Base details (3)
    registration: true, 
    dateOfFlight: true, 
    pilot: true, 
        
    // Departure details (10)
    passenger1: false,
    passenger2: false,
    departureFuelUpliftLeft: false,
    departureFuelUpliftRight: false,
    departureFuelLeft: true, 
    departureFuelRight: true, 
    oilUpliftLeft: false,
    oilUpliftRight: false,
    oilStatusLeft: true, 
    oilStatusRight: false, 
    // Arrival details (4)
    fromAirfield: true, 
    toAirfield: true, 
    flightDetails: false, 
    homeLandings: true,
    awayLandings: false,
    nightLandings: false,
    // Arrival Fuel (5)
    arrivalFuelUpliftLeft: false,
    arrivalFuelUpliftRight: false,
    arrivalFuelLeft: true, 
    arrivalFuelRight: true,  
    fuelUsed: true,
    // Flight times (6 + 3)
    offBlocks: true,
    onBlocks: true,
    blockDifference: true,
    takeOff: true,
    landing: true,
    flightTime: true,
    svcOff: true,
    svcOn: true,
    svcDifference: true,
    instrument: false,
    night: false,

    // New Defects (2)
    newDefect1: false,
    newDefect2: false,

    //Away landings (20)
    awayLandingLocation1: false,
    awayLandingQuantity1: true,
    awayLandingDetails1: false,
    awayLandingPaid1: false,
    awayLandingLocation2: false,
    awayLandingQuantity2: false,
    awayLandingDetails2: false,
    awayLandingPaid2: false,
    awayLandingLocation3: false,
    awayLandingQuantity3: false,
    awayLandingDetails3: false,
    awayLandingPaid3: false,
    awayLandingLocation4: false,
    awayLandingQuantity4: false,
    awayLandingDetails4: false,
    awayLandingPaid4: false,
    awayLandingLocation5: false,
    awayLandingQuantity5: false,
    awayLandingDetails5: false,
    awayLandingPaid5: false,
}

// ************************************************ SAMPLE FLIGHTS *************************************************

// let emptyFlight = new Flight();
// console.log(emptyFlight);

const background = {
    error: "background-color: rgb(245, 247, 177)",  // Soft Yellow
    // error: "background-color: rgb(240,240,240)", // Grey
    // error: "background-color: rgb(255,255,88)", // Bright Yellow
    // warning: "background-color: rgb(255,255,255)", // White 
    // warning: "background-color: rgb(240,240,240)", // Grey
    // warning: "background-color: rgb(255,255,255)", // soft yellow 
    // warning: "background-color: rgb(184,251,253)", // Bright Blue 
    // warning: "background-color: rgba(217,240,240)", // Soft Blue
    // warning: "background-color: rgba(229,248,253)", // Very Soft Blue
    warning: "background-color: rgba(254, 255, 237)", // Blanched Almond
    normal: "background-color: white",  
    grey: "background-color: rgb(240,240,240)" // Grey
}

let emptySubmission = {
    validationResultList: [],
    mostSevereIssue: {},
    data: {

    // Standard Flight Object (73)
    // Change Log (2)
        // String LastModifiedBy
        // Instant LastModifiedAt 
    // Base Details (9)
    id: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },
    registration: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    planeType: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    status: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },
    dateOpened: {
        value: dayjs(new Date()).format("YYYY-MM-DD, HH:mm"),
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    dateOfFlight: {
        value: dayjs(new Date()).format("YYYY-MM-DD"),
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    pilot: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    passenger1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    passenger2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    // Departure Fuel (4)
    departureFuelUpliftLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    departureFuelRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    },  
    // Departure Oil (4)
    oilUpliftLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilUpliftRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilStatusLeft: {
        value: "",
        status: "",
        background: background.grey,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    oilStatusRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    //Arrival details (4)
    fromAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    toAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightDetails: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },  
    homeLandings: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    awayLandings: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    nightLandings: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelUpliftRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    arrivalFuelRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },   
    fuelUsed: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Times (13)
    offBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    onBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    blockDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    takeOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    landing: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcOn: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    nightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    instrumentTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    instructorTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    multiPilotTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    // Defects (2) - refactor as a list
    newDefect1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    newDefect2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    
    // Away Landings (20) - refactor as a list
    awayLandingLocation1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    awayLandingDetails1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid1: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid2: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid3: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid4: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid5: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 

} // End submission.data object
}

let emptySubmissionSim = {
    validationResultList: [],
    mostSevereIssue: {},
    data: {

    // Standard Flight Object (73)
    // Change Log (2)
        // String LastModifiedBy
        // Instant LastModifiedAt 
    // Base Details (9)
    id: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },
    registration: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    planeType: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    status: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },
    dateOpened: {
        value: dayjs(new Date()).format("YYYY-MM-DD, HH:mm"),
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    dateOfFlight: {
        value: dayjs(new Date()).format("YYYY-MM-DD"),
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    pilot: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    passenger1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,    
        requiredOpen: true,
        requiredSubmit: true
    }, 
    passenger2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    // Departure Fuel (4)
    departureFuelUpliftLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },  
    // Departure Oil (4)
    oilUpliftLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilUpliftRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilStatusLeft: {
        value: "",
        status: "",
        background: background.grey,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },  
    oilStatusRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },  
    //Arrival details (4)
    fromAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },  
    toAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    flightDetails: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },  
    homeLandings: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandings: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    nightLandings: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelUpliftRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelLeft: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },  
    arrivalFuelRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    },   
    fuelUsed: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Times (13)
    offBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    onBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    blockDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    takeOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    landing: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcOn: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    nightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    instrumentTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    instructorTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    multiPilotTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    // Defects (2) - refactor as a list
    newDefect1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    newDefect2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    
    // Away Landings (20) - refactor as a list
    awayLandingLocation1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid1: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid2: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid3: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid4: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid5: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 

} // End submission.data object
}

let defaultOpenFlight1 = {

    // Default Open Flight (53)
    registration: {
        value: "GELKI",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    status: {
        value:"OPEN"
    },
    dateOfFlight: {
        value: "2023-11-11",
        // value:"",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    dateOpened: {
        // value: dayjs(new Date("11-11-2023-11:00:00")).format("YYYY-MM-DD, HH:mm"),
        value:"11-11-2023, 11:00",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    pilot: {
        value: "Hubert HighBypass",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    
    // Departure details (10)
    passenger1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    passenger2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelLeft: {
        value: 14,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    departureFuelRight: {
        value: 14,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    },  
    oilUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilStatusLeft: {
        value: "",
        status: "",
        background: background.grey,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    oilStatusRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    //Arrival details (4)
    fromAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    toAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightDetails: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },  
    homeLandings: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    
    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    arrivalFuelLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    arrivalFuelRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },   
    fuelUsed: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Flight times (9)
    offBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    onBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    blockDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    takeOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    landing: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcOff: {
        value: 1201,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcOn: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    // Defects (2)
    newDefect1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    newDefect2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Away Landings (20)
    awayLandingLocation1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity1: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    awayLandingDetails1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid1: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity2: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid2: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity3: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid3: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity4: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid4: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity5: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid5: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 

}
// console.log("In FlightDate, defaultOpenFlight1 -> ", defaultOpenFlight1);

let defaultOpenFlight2 = {

    // Default Open Flight (53)
    registration: {
        value: "GELKI",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    status: {
        value:"OPEN"
    },
    dateOfFlight: {
        value: "2023-11-11",
        // value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    dateOpened: {
        value: "11-11-2023, 11:03",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    pilot: {
        value: "Genghis Venturi",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    
    // Departure details (10)
    passenger1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    passenger2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelLeft: {
        value: 8,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    departureFuelRight: {
        value: 8,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    },  
    oilUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilStatusLeft: {
        value: "",
        status: "",
        background: background.grey,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    oilStatusRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    //Arrival details (4)
    fromAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    toAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightDetails: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },  
    homeLandings: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    
    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    arrivalFuelLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    arrivalFuelRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },   
    fuelUsed: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Flight times (9)
    offBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    onBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    blockDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    takeOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    landing: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcOff: {
        value: 1101,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcOn: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    // Defects (2)
    newDefect1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    newDefect2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Away Landings (20)
    awayLandingLocation1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity1: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    awayLandingDetails1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid1: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity2: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid2: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity3: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid3: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity4: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid4: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity5: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid5: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 

}

let defaultOpenFlight3 = {

    // Default Open Flight (53)
    registration: {
        value: "GELKO",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    status: {
        value:"OPEN"
    },
    dateOfFlight: {
        value: "2023-12-15",
        // value:"",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    dateOpened: {
        value: "15-12-2023, 20:00",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    pilot: {
        value: "Burt BourdonTube",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    
    // Departure details (10)
    passenger1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    passenger2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelLeft: {
        value: 24,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    departureFuelRight: {
        value: 24,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    },  
    oilUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    oilStatusLeft: {
        value: "",
        status: "",
        background: background.grey,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    oilStatusRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    //Arrival details (4)
    fromAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    toAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightDetails: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },  
    homeLandings: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    
    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    arrivalFuelLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    arrivalFuelRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },   
    fuelUsed: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Flight times (9)
    offBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    onBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    blockDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    takeOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    landing: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcOff: {
        value: 1001,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcOn: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    // Defects (2)
    newDefect1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    newDefect2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Away Landings (20)
    awayLandingLocation1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    awayLandingDetails1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid1: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid2: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid3: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid4: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid5: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 

}

let defaultOpenFlight4 = {

    // Default Open Flight (53)
    registration: {
        value: "GELKO",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    status: {
        value:"OPEN"
    },
    dateOfFlight: {
        value: "2023-12-17",
        // value:"",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    dateOpened: {
        value: "17-12-2023, 06:30",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    pilot: {
        value: "Penelope Propwash",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    
    // Departure details (10)
    passenger1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    passenger2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    departureFuelLeft: {
        value: 16,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    }, 
    departureFuelRight: {
        value: 16,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: true,
        requiredSubmit: true
    },  
    oilUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    oilStatusLeft: {
        value: "",
        status: "",
        background: background.grey,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    oilStatusRight: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    //Arrival details (4)
    fromAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    toAirfield: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightDetails: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    },  
    homeLandings: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    
    //Arrival Fuel (5)
    arrivalFuelUpliftLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    arrivalFuelUpliftRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    arrivalFuelLeft: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },  
    arrivalFuelRight: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    },   
    fuelUsed: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Flight times (9)
    offBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    onBlocks: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    blockDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    takeOff: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    landing: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    flightTime: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    svcOff: {
        value: 901,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcOn: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    svcDifference: {
        value: 0.0,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 

    // Defects (2)
    newDefect1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    newDefect2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: false,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    
    // Away Landings (20)
    awayLandingLocation1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity1: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: true
    }, 
    awayLandingDetails1: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid1: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity2: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails2: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid2: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity3: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails3: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid3: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity4: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails4: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid4: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingLocation5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingQuantity5: {
        value: null,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingDetails5: {
        value: "",
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 
    awayLandingPaid5: {
        value: false,
        status: "",
        background: background.normal,
        variant: "filled",
        reset: true,
        requiredOpen: false,
        requiredSubmit: false
    }, 

}

// DepartureFlight (57)
let defaultFlight1 = {
    // Entity Details (2)
    // Id
    // sortKey
    // Base details (5)
    registration: "GELKI", // registration
    status:"closed", // status
    dateOpened: new Date("2023-09-11"), // dateOpened
    dateOfFlight: new Date("2023-09-11"), // dateOfFlight
    pilot: "Penelope Propwash", // pilot

    // Departure details (10)
    student: "", // student
    passenger2: "", // passenger2
    departureFuelUpliftLeft: "", // departureFuelUpliftLeft
    departureFuelUpliftRight: "", // departureFuelUpliftRight
    departureFuelLeft: 10, // departureFuelLeft
    departureFuelRight: 10, // departureFuelRight
    oilUpliftLeft: "", // oilUpliftLeft
    oilUpliftRight: "", // oilUpliftRight
    oilStatusLeft: "1/2", // oilStatusLeft
    oilStatusRight: "", // oilStatusRight
    //Arrival details (4)
    fromAirfield: "EGHF", // from
    toAirfield: "EGHF", // to
    flightDetails: "Nax Ex", // flightDetails
    homeLandings: 1, // homeLandings

    // Arrival Fuel (5)
    arrivalFuelUpliftLeft: 5, // arrivalFuelUpliftLeft
    arrivalFuelUpliftRight: 5, // arrivalFuelUpliftRight
    arrivalFuelLeft: 10, // arrivalFuelLeft
    arrivalFuelRight: 10, // arrivalFuelRight
    // fuelUsed = calculatedFuelUsed

    // Flight times - (9)
    // B lock times
    offBlocks: "09:00", // offBlocks
    onBlocks: "10:50", // onBlocks
    blockDifference: 1.8,// blockTime = calculatedBlackTimes
    // T/O & landing times
    takeOff: "09:10", // takeOff
    landing: "10:40", // Landing
    flightTime: 1.5,// flight time = calculatedFlightTime
    //SVC times
    svcOff: 1201, // svcOff
    svcOn: 1202.5, // svcOn
    svcDifference: 1.5,// svcDifference = calculatedSVCDifference

    // Defects (2)
    defects:
    [
        {details: "Right Rear Nav Light Unserviceable"}, // newDefect1
        {details: "" }// newDefect2
    ],
    // Away landings (20)
    // Away landings 1
    awayLanding:
    [
    {awayLandingLocation1: "EGMD"}, // location1
    {awayLandingQuantity1: 2}, // quantity1
    {awayLandingDetails1: "ILS & RNP Low Approaches"}, // details1
    {awayLandingPaid1: "Paid"} // paid1}
    ],
    // Away landings 2-5 = "",
    awayLandingLocation2: "",
    awayLandingQuantity2: "",
    awayLandingDetails2: "",
    awayLandingPaid2: "",
    awayLandingLocation3: "",
    awayLandingQuantity3: "",
    awayLandingDetails3: "",
    awayLandingPaid3: "",
    awayLandingLocation4: "",
    awayLandingQuantity4: "",
    awayLandingDetails4: "",
    awayLandingPaid4: "",
    awayLandingLocation5: "",
    awayLandingQuantity5: "",
    awayLandingDetails5: "",
    awayLandingPaid5: "",
};

// DepartureFlight (57)
let defaultFlight2 = {
    // Entity Details (2)
    // Id
    // sortKey
    // Base details (5)
    registration: "GELKO", // registration
    status:"closed", // status
    dateOpened: new Date("2023-09-10"), // dateOpened
    dateOfFlight: new Date("2023-09-10"), // dateOfFlight
    pilot: "Cyril Slat", // pilot

    // Departure details (10)
    student: "", // student
    passenger2: "", // passenger2
    departureFuelUpliftLeft: "", // departureFuelUpliftLeft
    departureFuelUpliftRight: "", // departureFuelUpliftRight
    departureFuelLeft: 15, // departureFuelLeft
    departureFuelRight: 15, // departureFuelRight
    oilUpliftLeft: "", // oilUpliftLeft
    oilUpliftRight: "", // oilUpliftRight
    oilStatusLeft: "3/4", // oilStatusLeft
    oilStatusRight: "3/4", // oilStatusRight
    //Arrival details (4)
    fromAirfield: "EGHF", // from
    toAirfield: "EGHF", // to
    flightDetails: "IFR Training", // flightDetails
    homeLandings: 1, // homeLandings

    // Arrival Fuel (5)
    arrivalFuelUpliftLeft: "", // arrivalFuelUpliftLeft
    arrivalFuelUpliftRight: "", // arrivalFuelUpliftRight
    arrivalFuelLeft: 10, // arrivalFuelLeft
    arrivalFuelRight: 10, // arrivalFuelRight
    // fuelUsed = calculatedFuelUsed

    // Flight times - (9)
    // B lock times
    offBlocks: "10:00", // offBlocks
    onBlocks: "11:20", // onBlocks
    blockDifference: 1.3, // blockDifference = calculatedBlackTimes
    // T/O & landing times
    takeOff: "10:10", // takeOff
    landing: "11:10", // Landing
    flightTime: 1,
    //SVC times
    svcOff: 1100, // svcOff
    svcOn: 1101, // svcOn
    svcDifference: 1, // svcDifference = calculatedSVCDifference

    // Defects (2)
    defects: [
        {details: ""}, // newDefect1
        {details: ""} // newDefect2
    ],
    // Away landings (20)
    // Away landings 1
    awayLanding:
    [
        {awayLandingLocation1: "EGJA", 
        awayLandingQuantity1: 2, 
        awayLandingDetails1: "ILS & RNP Low Approaches", 
        awayLandingPaid1: "Paid"}
    ],
    // Away landings 2-5 = "",
    // awayLandingLocation2: "",
    // awayLandingQuantity2: "",
    // awayLandingDetails2: "",
    // awayLandingPaid2: "",
    // awayLandingLocation3: "",
    // awayLandingQuantity3: "",
    // awayLandingDetails3: "",
    // awayLandingPaid3: "",
    // awayLandingLocation4: "",
    // awayLandingQuantity4: "",
    // awayLandingDetails4: "",
    // awayLandingPaid4: "",
    // awayLandingLocation5: "",
    // awayLandingQuantity5: "",
    // awayLandingDetails5: "",
    // awayLandingPaid5: "",
};
let defaultCamoLogLists = {
    "daily": [],
    "weekly": [],
    "monthly": []
}

const defaultFlightList = [defaultFlight1, defaultFlight2];
// console.log(defaultFlight1);
const defaultOpenFlightList = {
    "PLANE": [],
    "GELKI": [defaultOpenFlight1, defaultOpenFlight2],
    "GELKO": [defaultOpenFlight3, defaultOpenFlight4],
    "GFOOJ": []
    }



export {
    defaultFlightList, 
    defaultOpenFlightList,
    defaultFlight1, 
    defaultFlight2,
    defaultCamoLogLists,
    emptySubmission,
    emptySubmissionSim,
    flightMandatoryFields, 
    background
    };
