// Import libraries
import React, {useState, useEffect, useReducer} from 'react';
import { API_URL } from '../../../config/config.js';
import axiosInstance from '../../utilities/security/AxiosConfig.js';
import {defaultAircraftList, emptyAircraftRequest} from '../../aircraft/AircraftData.js';
import {CreateAircraftList} from '../../aircraft/CreateAircraftList.js';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import {Box, Button, responsiveFontSizes} from "@mui/material";
import AdminAircraftTable from "./AdminAircraftTable.js"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AdminAircraftBanner from './AdminAircraftBanner.js';
import AdminAircraftDefects from './AdminAircraftDefects.js';
import AircraftFormReducer from "../../dispatchersAndReducers/AircradftFormReducer.js";
import Feedback from '../../utilities/feedback/Feedback.js';
import Validate from '../../utilities/validation/Validate.js';
import { GetAircraftData } from '../../utilities/database/GetAircraftData.js';
import { useAuthFirebase } from '../../utilities/security/AuthContextFirebase.js';
import {useMediaQuery} from '@mui/material';


// Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import CSS, images, icons & static data
import '../../flights/techLog/TechLog.css';
import './AdminAircraft.css'
import { successMessages } from '../../utilities/validation/AircraftValidations.js';
import { background } from '../../flights/flightData/FlightData.js';
import {emptyAircraftSubmission} from "../../aircraft/AircraftData.js";
import { mobileThreshold } from '../../utilities/environment/EnvironmentData.js';


// ******************************* MAIN FUNCTION ************************************************
function AircraftAdminMain(props) {
    // Initialise context using default variables
    const title = "Aircraft Admin.";
    const aircraftSelectionKey = 0
    const calledFrom = "Admin"
    


    // *********************** INITIALISE STATE VARIABLES **********************************
    const [aircraftList, setAircraftList] = useState(defaultAircraftList);
    const [selectedAircraft, setSelectedAircraft] = useState(aircraftList[aircraftSelectionKey]);
    const [registration, setRegistration] = useState(selectedAircraft._registration);
    const [databaseConnection, setDatabaseConnection] = useState(true);
    const [aircraftSubmission, dispatch] = useReducer(AircraftFormReducer, emptyAircraftSubmission);
    // const [checked, setChecked] = useState(false);
    const [editing, setEditing] = useState(false)
    var databaseSubmission = {}   
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
    const [adminAircraftFrameClassName, setAdminAircraftFrameClassName] = useState("admin-aircraft-frame")

    const [serviceEventList, setServiceEventList] = useState([]);
    const [openButton, setOpenButton] = useState(false);
    const [dialogueResponse, setDialogueResponse] = useState("dialogueCancel");

    // Security set up
    const {authUser} = useAuthFirebase();
    const [jwt, setJwt] = useState(null);
    
    // Validations & SnackBar
    const [result, setResult] = useState({status: "", message: "" })
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [dialogueStatus, setDialogueStatus] = useState(false);

    // Initialise the most severe issue container 
    let mostSevereIssue = {
        status: "success",
        message: successMessages.updated,
        field1: "",
        field2: "",
        field3: "",
        field4: ""
    };

    dayjs.extend(utc);

    useEffect(() => {
        if (media) {
            setAdminAircraftFrameClassName("admin-aircraft-frame");
        } else {
            setAdminAircraftFrameClassName("admin-aircraft-frame-mobile");
        }
    },[media]);

    // ********************* Snackbar & Dialogue Update functions ***************************
    function UpdateSnackBarStatus (newStatus) {
            // console.log("In TechLog, UpdateSnackBarStatus, newStatus->", newStatus);
        setSnackBarStatus(newStatus);
            // console.log("In TechLog, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
    }
    function UpdateDialogueStatus (newStatus) {
            // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
        setDialogueStatus(newStatus);
            // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    function UpdateDialogueResponse (response) {
            // console.log("In TechLog, UpdateDialogueResponse, response->", response);
        setDialogueResponse(response);
            // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    // Update Validation result functions
    function UpdateResult (newResult) {
        // console.log("In TechLog, UpdateResult, newResult -> ", newResult)
        if (newResult !== undefined) {
            setResult(newResult);
        }
            // console.log("In TechLog, UpdateResult, result -> ", result)
        }

    // ********************** SUB-FUNCTIONS ******************************
    function UpdateEditing(isChecked) {
            // console.log("In AdminAircraftMain, UpdateEditing, isChecked->", isChecked);
        setEditing(isChecked);
    }


    // ****************** Initialise aircraftSubmission object using Aircraft Reducer ***********************
    function UpdateAircraftSubmission(selectedAircraft) {
        let action = {
            type: "update",
            selectedAircraft: selectedAircraft
        }
            // console.log("In InitialiseAircraftSubmission, action->", action)
        dispatch(action);
    }
    // Populate the initial form with the details of the dummy aircraft - PLANE
    useEffect(() => {
        UpdateAircraftSubmission(selectedAircraft);

            // console.log("In AdminAircraftMain, in useEffect, aircraftSubmission->", aircraftSubmission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[registration, aircraftList]);

    useEffect( () => {
        const TestJwt = async () => {
            if (authUser) {
                const token = await authUser.getIdToken();
                setJwt(token);
            }
        }
        TestJwt();
    },[jwt])

    // Update Registration functions for other components
    function UpdateSelection (selection, planeList) {
        // setRegistration(selection);
        // AircraftHandleClear();
        if (planeList != null) {
            planeList.forEach((plane) => {
                if (plane._registration === selection) {
                    setSelectedAircraft(plane);
                    setRegistration(plane._registration)
                }
            });
        }
    }

    const PlaneData = async () => {
        let responseObject = await GetAircraftData()
            console.log("In AdminAircraftMain, responseObject->", responseObject);
            setDatabaseConnection(responseObject.databaseConnection);
        if (responseObject.data) {
            UpdateSelection(responseObject.data.aircraftList[0]._registration, responseObject.data.aircraftList);
            setAircraftList(responseObject.data.aircraftList);
            setServiceEventList(responseObject.data.serviceEventList)
    
        }
    }

    useEffect(() => {

        PlaneData();
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [databaseConnection]);

    // ************************* DATABASE FUNCTION - UPDATE AIRCRAFT *******************************
    const submitAircraft = async () => {

        try {
            databaseSubmission = {...emptyAircraftRequest};
            Object.keys(aircraftSubmission).forEach((object) => {
                // databaseSubmission = {...emptyAircraftRequest};
                switch(object) {
                    case "oilLevels": 
                            // console.log("In AdminAircraft, submitAircraft, object->", aircraftSubmission[object])
                            databaseSubmission.oilLevels = [];
                            aircraftSubmission.oilLevels.forEach((level) => {    
                            databaseSubmission.oilLevels.push(level.value);
                                // console.log("In AdminAircraft, submitAircraft, level->", level.value)
                                // console.log("In AdminAircraft, submitAircraft, databaseSubmission->", databaseSubmission)
                        })
                    
                    break;
                    case "defects":
                            // console.log("In AdminAircraft, submitAircraft, aircraftSubmission.defects->", aircraftSubmission)
                            databaseSubmission.defects = [];
                            aircraftSubmission.defects.forEach((defect) => {
                            databaseSubmission.defects.push(defect);
                                // console.log("In AdminAircraft, submitAircraft, defect->", defect)
                                // console.log("In AdminAircraft, submitAircraft, databaseSubmission->", databaseSubmission)
                        })
                    break;
                    default:      
                        databaseSubmission[object] = aircraftSubmission[object].value;
                            // console.log("In AdminAircraft, submitAircraft, object->", object)
                            // console.log("In AdminAircraft, submitAircraft, databaseSubmission->", databaseSubmission)
                }
            });
            
                // console.log("In AdminAircraftMain, submitFlight, databaseSubmission -> ", aircraftSubmission)
                const response = await axiosInstance.post(`${API_URL}/planes/updateCreatePlane`, databaseSubmission)
                const token = await authUser.getIdToken();    
                    // console.log("In submitAircraft, token>", token);
            return response;
        } catch(error) {
            console.error("Error submitting an Aircraft", error)
            return "error";
        
        }
    }

    // *********************** AIRCRAFT DISPATCHERS FOR MANAGING THE AIRCRAFT SUBMISSION OBJECT ****************************
    // Change fields
    function AircraftHandleChange(e) {
            // console.log("In AircraftHandleChange, e->", e)
        let action = {}
        if (e.name === "defectCheckbox") {
            action = {
                type: "edit",
                name: e.name,
                id: e.id,
                status: e.value,
                value: e.value,
                defectStatus: e.value,
                event: e

            }
        } else {
            action = {
                type: "edit",
                name: e.name,
                event: e,
                value: e.value
            }
        }
            // console.log("In AdminAircraftMain, AircraftHandleChange, e->", e, "action", action)
        if (action !== undefined) {
            dispatch(action)
        }
    }

    const AircraftHandleClear = () => {
        dispatch({
            type: 'clear',
        })
        // Post clear actions

        // Reset 
        let action = {
            type: "edit",
            name: "registration",
            value: registration
        }
        AircraftHandleChange(action)
    }

    // Handle aircraft Submit
    const AircraftSubmit = async () => {

        let type = "updateAircraft"
        if (selectedAircraft.registration === "NEW PLANE") {
            type = "newAircraft"
        }
        // Validate the aircraft submission object
            console.log("In AdminAircraftMain, aircraftSubmission->", aircraftSubmission ,"selectedAircraft->", selectedAircraft,"type->", type ,"aircraftList->", aircraftList);
        let validationResultList = Validate(aircraftSubmission, selectedAircraft, type, aircraftList);
            // console.log("In AdminAircraftMain, post validation, validationResultList->", validationResultList);

        // Update the submission object
        if (type === "newAircraft") {
        dispatch({
            type: 'newAircraft',
            validationResultList: validationResultList,
        }) 
        } else {
            dispatch({
                type: 'updateAircraft',
                validationResultList: validationResultList,
            })
        } 
           
        // Parse the validation results and identify the most severe issue
        validationResultList.forEach((result) => {
            if (mostSevereIssue.status !== "error") {
                mostSevereIssue = result;
                    // console.log("In Reducer, in result list review, result, mostSevereIssue ->", result, mostSevereIssue);
            }})

        // ***************** If aircraft has no errors, submit it to the database *****************
            console.log("In AdimAicraftMain, mostSeverIssue->", mostSevereIssue);
        if (mostSevereIssue.status === "success") {  
            HandleContinueAircraft();
            //     // console.log("In AdminAircraft, pre-submission", aircraftSubmission);
            // var submissionAircraftResponse = await submitAircraft(aircraftSubmission)
            //     // console.log("In AircraftAdmin, submissionAircraftResponse->", submissionAircraftResponse)
            // if (submissionAircraftResponse !== "error") { 
           
            //     let responseObject = await GetAircraftData()
            //     setDatabaseConnection(responseObject.databaseConnection)
            //     UpdateAircraftSubmission(selectedAircraft)
            //     UpdateResult(mostSevereIssue);
            //     FeedbackTrigger(mostSevereIssue);
                
            // } else {
                
            //     mostSevereIssue = {
            //         status: "error",
            //         message: "CANNOT CONNECT TO THE DATABASE"
            //     }
            //     FeedbackTrigger(mostSevereIssue);
            // }
        } else {
            FeedbackTrigger(mostSevereIssue);
        }
    }
        // ********************** FEEDBACK based on most severe issue and update fields ************************ 
        function FeedbackTrigger(mostSevereIssue) {
            // Feedback based on most severe issue and update form accordingly
            if (mostSevereIssue !== undefined) {
                    // console.log("In TechLogHandleOpen, post dispatch, submission !== undefined -> ", submission)
                UpdateResult(mostSevereIssue);
                    // console.log("In TechLogHandleOpen, result -> ", result);
                switch(mostSevereIssue.status) {
                    case "success":
                        // UpdateResult(mostSevereIssue);
                        UpdateSnackBarStatus(true);
                        // if (snackBarStatus === false) {
                        //     // TechLogHandleClear();
                            GetAircraftData();
                        // }
                    break;
        
                    case "error":
                            // console.log("In AircraftAdminMain, In Feedback, submission ->", aircraftSubmission)
                        UpdateResult(mostSevereIssue);
                        UpdateSnackBarStatus(true);
                    break;
        
                    case "warning":
                        UpdateResult(mostSevereIssue)
                        UpdateDialogueStatus(true);
                    break;
        
                    default: console.log("Unknown validation result")
                }
            }
        }

    // *********************** HANDLE WARNING BOX CONTINUE AIRCRAFT *****************************   
    const HandleContinueAircraft = async () => {
        // Attempt to write the flight to the database
        let responseObject = ""
        responseObject = await submitAircraft(aircraftSubmission);
            // console.log("In aircraftAdmin, post HandleContinue, submitAircraftResponse-> ", submitAircraftResponse)
            // console.log("In TechLog, TechLogHandleOpenSubmit, submitFlightResponse -> ", submitFlightResponse);

        // If submission successful, clear the current render & reset the validation issues
        if (responseObject.databaseConnection !== "error") { 
                
            UpdateDialogueResponse("dialogueCancel")
            mostSevereIssue = {
                status: "success",
                message: successMessages.updated,
                field1: "",
                field2: "",
                field3: "",
                field4: ""
            };
                    // Reset Aircraft information
            let responseObject = await GetAircraftData();
                console.log("In GetAircraftData, responseObject->", responseObject);
            // Handle response aircraft data
            setDatabaseConnection(responseObject.databaseConnection);
            setAircraftList(responseObject.data.aircraftList);
            setServiceEventList(responseObject.data.serviceEventList)
            UpdateSelection(selectedAircraft._registration, responseObject.data.aircraftList)
            // UpdateSelection(responseObject.data.aircraftList[0]._registration, responseObject.data.aircraftList)

                console.log("In AircraftMain, GetAircraftData called... mostSevereIssue->", mostSevereIssue);
            // Update the mostSevereIssue and send user the relevant user feedback
            UpdateResult(mostSevereIssue);
            FeedbackTrigger(mostSevereIssue)
            // console.log("In TechLog, Feedback, dialogueResponse -> ", dialogueResponse)
            // TechLogHandleClear();
        } else {
                console.log("In HandleContinueAircraft, failed submission")
            mostSevereIssue = {
                status: "error",
                message: "CANNOT CONNECT TO THE DATABASE"
            }
        }

        FeedbackTrigger(mostSevereIssue)
         
    } 
    
    
    // *********************** AIRCRAFT ADMIN RENDER ****************************
        // console.log("In AdminAircraftMain, aircraftSubmission->", aircraftSubmission)
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            
            <Box>
                <AdminAircraftBanner 
                    UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                    UpdateEditing = {UpdateEditing}
                    snackBarStatus = {snackBarStatus} 
                    UpdateResult = {UpdateResult}
                    AircraftHandleClear = {AircraftHandleClear} 
                    AircraftSubmit = {AircraftSubmit}
                    className = "banner" 
                    title = {title} 
                    submission = {aircraftSubmission} 
                    databaseConnection = {databaseConnection} 
                    aircraftList={aircraftList} 
                    selectedAircraft = {selectedAircraft} 
                    registration = {registration}
                    calledFrom = "adminAircraft"
                    authUser = {authUser}
                    UpdateSelection={UpdateSelection}/> 
                {/* <Feedback sx= {{flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap'}} HandleContinueAircraft = {HandleContinueAircraft} DialogueStatus = {DialogueStatus} UpdateDialogueStatus = {UpdateDialogueStatus} snackBarStatus = {snackBarStatus} UpdateSnackBarStatus = {UpdateSnackBarStatus} result = {result}/>  */}
            </Box>
            <Box className = {adminAircraftFrameClassName}>
                <AdminAircraftTable 
                    editing = {editing}
                    selectedAircraft = {selectedAircraft} 
                    aircraftSubmission = {aircraftSubmission}
                    AircraftHandleChange = {AircraftHandleChange}/>
                <AdminAircraftDefects 
                    // checked = {checked}
                    editing = {editing}
                    selectedAircraft = {selectedAircraft} 
                    aircraftSubmission = {aircraftSubmission}
                    AircraftHandleChange = {AircraftHandleChange}/>
                {/* <Box className = "bannerRow2">*/}

            {/*</Box> */}
            </Box>

            <Box className = "feedback" sx={{zIndex: "6", position: "sticky", left: "0px", bottom: "80px", margin: "80px"}}>
                <Feedback  HandleContinue = {HandleContinueAircraft} 
                        dialogueStatus = {dialogueStatus} 
                        UpdateDialogueStatus = {UpdateDialogueStatus} 
                        snackBarStatus = {snackBarStatus} 
                        UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                        result = {result}/> 
            </Box>
        </LocalizationProvider>
        
    );
};

export default AircraftAdminMain;
