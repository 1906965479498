//Import libraries
import React, { useEffect, useState } from 'react';
import { Box, Container } from "@mui/system";
import CourseTile from "./CourseTile";
import { useNavigate } from 'react-router-dom';
import BannerTitle from '../flights/techLog/banner/BannerTitle';
import CourseRadioButtons from './CourseRadioButtons';
import { useAuthFirebase } from '../utilities/security/AuthContextFirebase';
import { GetUserRoles } from '../utilities/security/AuthUtilities';
import _ from "lodash";


// Import CSS, images, icons & static data
import "./CourseSelection.css"
import "../flights/techLog/banner/Banner.css"
import { GetCourseData, courseData } from '../utilities/database/GetCourseData';
// import { co } from '@fullcalendar/core/internal-common';


// Import CSS, Static Data, Colours and Fonts
import { rolesTemplate } from '../utilities/database/GetRolePermissions';


//**************************** MAIN FUNCTION ***************************** */
function CourseSelection(props) {
    let uniqueCourses = [];
    const [courseStaticList, setCourseStatic] = useState(courseData); 
    const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
    const [currentUserRoles, setCurrentUserRoles] = useState();
    let userRoles = null;
    let responseObject = null;
    // let chosenRole
    const [courseList, setCourseList] = useState([]); 
    let courseSelection = "Courses"
    const [highestUserRole, setHighestUserRole] = useState("student")
    const [chosenRole, setChosenRole] = useState("student")
    // const HandleChange = (event) => {setHighestUserRole(event.target.value)};
    const [databaseConnection, setDatabaseConnection] = useState(true);
    const [result, setResult] = useState({status: "", message: "" })

    function UpdateDatabaseConnection (response) {
        // console.log("In TechLog, UpdateDialogueResponse, response->", response);
        setDatabaseConnection(response);
        // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    
    function UpdateResult (newResult) {
        // console.log("In TechLog, UpdateResult, newResult -> ", newResult)
        if (newResult !== undefined) {
            setResult(newResult);
        }
            // console.log("In TechLog, UpdateResult, result -> ", result)
    }

    function HandleChange(event) {
        setChosenRole(event.target.value);
            console.log("In CourseSelection, chosenRole->", chosenRole);
    }

   async function UpdateRoles() {
       
        responseObject = await GetUserRoles();
        if (responseObject.databaseConnection) {
            setCurrentUserRoles(responseObject.data);
            UpdateDatabaseConnection(responseObject.databaseConnection);
            userRoles = _.cloneDeep(responseObject.data);
                console.log("In CourseSelection, responseObject->", responseObject)
                console.log("In CourseSelection, userRoles->", userRoles)
            return (
                userRoles
            );
        } else {
            UpdateDatabaseConnection(responseObject.databaseConnection);
            UpdateResult(responseObject.data);
        }

            // console.log("In FlightLogGrid, responseObject->", responseObject)
            console.log("In CourseSelection, currentUserRoles->", currentUserRoles);
    }

    // useEffect (() => {
    //     // authUser ? setCurrentUser(authUser.displayName) : setCurrentUser("");
    //     UpdateRoles();
    // }, [authUser]); 

    useEffect(() => {

        // const GetCourses = async () => {
        //     setCourseList(await CreateCourseList());
        //     // console.log("In CourseSelection, courseList II ->", courseList);
        // }
        // GetCourses();

        const RefreshRoles = async () => {
            setCurrentUserRoles(await UpdateRoles());
        }

        RefreshRoles();
            console.log("In CourseSelection, currentUserRoles->", currentUserRoles);
            console.log("In CourseSelection, authUser->", authUser);
            // console.log("In CourseSelection, userRoles->", userRoles);
    }, [authUser])

    // useEffect( () => {
    //     const TestJwt = async () => {
    //         if (authUser) {
    //             const token = await authUser.getIdToken();
    //             setJwt(token);
    //         }
    //     }
    //     TestJwt();
    // },[jwt])

    // function HandleChange(event) {;
    //     chosenRole = event.target.value;
    // }
        

    // async function CourseRadioButtons() {
    //     if (currentUserRoles) {
    //         return (
    //             CourseRadioButtons (HandleChange, currentUserRoles) 
    //         );
    //     }
    // }

    function RenderCourseRadioButtons() {
            // console.log("In RenderCourseRadioButtons, userRole->", userRoles);
            console.log("In RenderCourseRadioButtons, currentUserRole->", currentUserRoles);
        if (currentUserRoles && (typeof currentUserRoles.then !== "function")) {
                console.log("In RenderCourseRadioButtons, currentUserRole->", currentUserRoles);
            return (
                // <Box></Box>
                <CourseRadioButtons currentUserRoles = {currentUserRoles} HandleChange = {HandleChange} chosenRole = {chosenRole}/>
            );
        } else {
            console.log("In RenderCourseRadioButtons, JUST RETURN A BOX");
            return (
                <Box></Box>
            );
        }

    }


    async function CreateCourseList() {

        let responseObject = {}
        responseObject = await GetCourseData();
        if (responseObject.databaseConnection) {
            let courses = responseObject.data;
                // console.log("In CourseSelection, courseList I->", courses);

            if (courses.length !== 0) {

                let statusPriority = {
                    APPROVED: 4,
                    DRAFT: 3,
                    DEPRECATED: 2,
                    DELETED: 1
                }

                uniqueCourses = courses.reduce(()=> {
                    courses.reduce((accumulator, current) => {
                        const existing = accumulator.find(course => course.name === current.name);
                    
                        if (!existing || statusPriority[current.status] > statusPriority[existing.status]) {
                        // If the course does not exist, or the current course has a higher priority, replace it
                        return accumulator.filter(course => course.name !== current.name).concat(current);
                        }
                    
                        // Otherwise, keep the existing one
                        return accumulator;
                    }, []);

                });
                    // console.log("In CourseSelection, uniqueCourses I->", uniqueCourses);

            } else {
                    // console.log("In CourseSelection, uniqueCourses II->", uniqueCourses);
                uniqueCourses = []
            }
        } else {
            // setUniqueCourses([])
            uniqueCourses = []
            // console.log("In CourseSelection, uniqueCourses III->", uniqueCourses);
        }

            // console.log("In CourseSelection, uniqueCourses IV->", uniqueCourses);
        return uniqueCourses
    }

    useEffect(() => {
        const GetCourses = async () => {
            setCourseList(await CreateCourseList());
            // console.log("In CourseSelection, courseList II ->", courseList);
        }
        GetCourses();
    }, [])

    // console.log("In CourseSelection, courseList III ->", courseList);

    function RenderCourseTiles() {

        // let courseList = []
        // courseList = await CreateCourseList();

        let courseTilesList = []
            // console.log("In RenderCourseTiles, courseList->", courseList);
            // console.log("In RenderCourseTiles, courseStaticList->", courseStaticList);
        if (courseStaticList.length !== 0) {
            courseStaticList.forEach((courseStatic) => {
                    if (courseList.length !== 0) {
                        if (courseStatic.name === "Multi-Engine Piston Rating") {
                            // console.log("In RenderCourseTiles, courseList III->", courseList)
                        }
                        uniqueCourses.forEach((course) => {
                            if (courseStatic.name === course.name) {
                                courseStatic = _.cloneDeep(course);
                                    // console.log("In RenderCourseTiles, courseStatic->", courseStatic)
                            }
                        })
                    }
                if (courseStatic.hasCurriculum) {
                    courseTilesList.push(
                        <CourseTile courseStatic = {courseStatic} key = {courseStatic.name} handleNavigate = {handleNavigate}/>
                    )
                }
            });
        }
        return (courseTilesList);
    }
        // console.log("In RenderCourseTiles, courseBoxes->", courseTiles)

 
    const navigate = useNavigate();
    const handleNavigate = (path) => {
            // console.log("In CourseSelection, handleNavigate, path->", path);   
        navigate(path);
    }

        // console.log("In CourseSelection, courseRadioButtons->", courseRadioButtons);

    return (
        <Box>
            <Box className = "course-selection-title-row">
                <Box className = "course-selection-title">
                    <BannerTitle title = {courseSelection}/>
                </Box>
                <Box className = "course-selection-radio">
                    <RenderCourseRadioButtons/>
                </Box>
                {/* <Box sx = {{color: "red", ml: "10px", fontSize: "large"}}>
                    UNDER CONSTRUCTION
                </Box> */}
            </Box>
            <Box className = "courses-page">
                <Box className = "courses-frame" sx = {{ backgroundColor: "white"}}>
                    {/* {courseTiles} */}
                    <RenderCourseTiles/>
                </Box>
            </Box>
        </Box>
    );


};

export {CourseSelection}