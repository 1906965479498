// Import libraries
import { useEffect, useState } from "react";
import {Box, FormControlLabel} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import {Checkbox} from '@mui/material';
import { AssignUserRoles } from "../../utilities/security/AuthUtilities";
import _ from "lodash";
import PersonAdminButtons from "./AdminPersonButtons";

// Import CSS, images, icons & static data
import "./AdminPerson.css"
import { mobileThreshold } from "../../utilities/environment/EnvironmentData";
import { rolesTemplate } from "../../utilities/database/GetRolePermissions";
import { Sledding } from "@mui/icons-material";





// ****************************** MAIN FUNCTION **********************************
export default function AdminPersonRoles(props) {
    // props.AddRoles(props.selectedPerson);
    // if (props.selectedPerson) {
        console.log("In AdminPersonRoles, props.selectedPerson->", props.selectedPerson)
    // }
    // const [selectedPersonRoles, setSelectedPersonRoles] = useState();
    const [adminButtonClass, setAdminButtonClass] = useState();
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)

    function HandleClick(e) {
            // console.log("In AdminPersonRoles, onClick, props.selectedPerson->", props.selectedPerson);
        props.SubmitClaims(props.selectedPerson);
    }

    function HandleRoleChange(e) {
            console.log("In AdminPersonRoles, onChange->", e);

        var role = e.target.name;
        var requestedCheckBoxValue = null
        if (e.target.checked) {
            requestedCheckBoxValue = true
        } else {
            requestedCheckBoxValue = false
        }
        props.UpdateRoles(props.selectedPerson, role, requestedCheckBoxValue)
    }
    
    function RenderRoleCheckBoxes(selectedPerson) {
        if (selectedPerson) {
            console.log("In AdminPersonRoles, selectedPerson->", selectedPerson);
           
           let roleList = Object.keys(props.selectedPerson.roles)
                .filter((role) => role !== "admin")
                .reduce((roleList, role) => {
                    roleList[role] = props.selectedPerson.roles[role];
                    return roleList;
                }, {});

                console.log("In AdminPersonRoles, roleCheckBoxes->", roleList);

            // setRows(rows.filter((row) => row.id !== id));
            let roleCheckBoxes = Object.keys(roleList).map((role) => 
                <FormControlLabel 
                    label = {role}
                    key = {role}
                    control = {
                        <Checkbox
                            checked = {props.selectedPerson.roles[role].status}
                            value = {props.selectedPerson.roles[role].status}
                            name = {role} id = {role}
                            disabled = {props.editing === false}
                            onChange = {e => HandleRoleChange(e)}>
                        </Checkbox>
                    }
                />
            )
                // console.log("In AdminPersonRoles, roleCheckBoxes->", roleCheckBoxes)
            return roleCheckBoxes;
        }
    }

    function RenderSelectPrompt() {
        return (
            <Box className = "admin-fields-container-empty">
                <Box className = "admin-subtitles-roles">Roles</Box>
                <Box className= "admin-checkboxes-empty" sx = {{color: "grey"}}>
                    SELECT A PERSON...
                </Box>
                
            </Box>
        )
    }

    function MainRender(selectedPerson) {
            console.log("In AdminPersonRoles, RenderRoles, selectedPerson->", selectedPerson);

        if (selectedPerson !== undefined && selectedPerson !== {}) {
            if (selectedPerson.roles !== undefined) {
                console.log("In AdminPersonRoles, RenderRoles, props.selectedPerson->", selectedPerson);

                let roleCheckBoxes = RenderRoleCheckBoxes(selectedPerson);
                return (
                    <Box className = "admin-fields-container">
                        <Box>
                            <Box className = "admin-subtitles-roles">Roles</Box>
                            <Box className = "admin-checkboxes">
                                {roleCheckBoxes}
                            </Box>
                        </Box>
                        <Box className = {adminButtonClass}>
                            <PersonAdminButtons 
                                buttonLabel = "UPDATE" 
                                editing = {props.editing} 
                                HandleClick = {HandleClick}
                                selectedPerson = {props.selectedPerson}/>
                        </Box>
                    </Box>
                );
            } else {
                    console.log("In AdminPersonRoles, MainRender Alt 1, selectedPerson->", selectedPerson);
                return (   
                    <RenderSelectPrompt/>
                );
            }
        } else {
                console.log("In AdminPersonRoles, MainRender Alt 2, selectedPerson->", selectedPerson);
            return (
                <RenderSelectPrompt/>
            );
        }
    }   
    // **************************** MANAGE MOBILE TRANSITION ************************************* 
    useEffect(() => {
        if (media) {
            setAdminButtonClass("button");
        } else {
            setAdminButtonClass("button-mobile");
        }
    },[media]);
       
    // **************************** MANAGE EVENT BASED RENDERS ************************************* 

    
    useEffect(() => {
            console.log("In AdminPersonRoles, main useEffect, props.selectedPerson->", props.selectedPerson);
        MainRender(props.selectedPerson);
            
    },[props.selectedPerson])
    

    //******************************** MAIN RENDER FUNCTION ****************************** */

        // console.log("In AdminPersonRoles, RenderRoles, selectedPerson->", props.selectedPerson);
    return MainRender(props.selectedPerson);
  
    
}

