// Import libraries
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainMenu from "./components/mainMenu/MainMenu.js";
import MenuMiniMobileLogs from './components/mainMenu/MenuMiniMobileLogs.js'
import Home from './components/homePage/Home';
import { TechLog } from "./components/flights/techLog/TechLog.js";
import FlightLog from './components/reports/flightLog/FlightLog';
import CamoLog from './components/reports/camoLog/CamoLog';
import PilotLog from './components/reports/pilotLog/PilotLog';
import { Container } from "@mui/material";
import Bookings from './components/bookings/Bookings';
import { CourseSelection } from './components/courses/CourseSelection';
import AdminAircraftMain from "./components/admin/aircraft/AdminAircraftMain.js"; 
import AdminCourseMain from './components/admin/course/AdminCourseMain.js';
import AdminPersonMain from './components/admin/person/AdminPersonMain.js';
import { AuthProviderFirebase } from './components/utilities/security/AuthContextFirebase';
import { clearAuthState } from './components/utilities/security/AuthUtilities';
import MenuMiniMobileBookingsAndCourses from './components/mainMenu/MenuMiniMobileBookingsAndCourses';
import { CourseMain } from './components/courses/CourseMain';


// Import CSS, images and icons
import './components/flights/techLog/TechLog.css';

// import "./components/mainMenu/MainMenu.css";



export default function App() {
  // const [currentUser, setCurrentUser] = useState("anonymous")

// useEffect(() => { 
//   reAuthenticate();
// }, []);

  useEffect(() => {
    // Function to clear authentication state
    clearAuthState();
  
    // Call clearAuthState when the component mounts
  
    // Clean-up function to unsubscribe from any subscriptions or clear timeouts
  }, []);

  return(
    <React.StrictMode>
      <AuthProviderFirebase>
        <BrowserRouter>
          {/* <ThemeProvider theme={azTechTheme}> */}
            <Container>
                <MainMenu/>
            </Container>
            <Routes>
              <Route index element  = {<Home/>}/>
              <Route path = "/techLog" element = {<TechLog/>}/>
              <Route path = "/flightLog" element = {<FlightLog/>}/>
              <Route path = "/camoLog" element = {<CamoLog/>}/>
              <Route path = "/pilotLog" element = {<PilotLog/>}/>
              <Route path = "/bookings" element = {<Bookings/>}/>
              <Route path = "/CourseSelection" element = {<CourseSelection/>}/>
              <Route path = "/adminAircraft" element = {<AdminAircraftMain/>}/>
              <Route path = "/adminPerson" element = {<AdminPersonMain/>}/>
              <Route path = "/adminCourse" element = {<AdminCourseMain/>}/>
              <Route path = "/Course/:courseId" element = {<CourseMain/>}/>
              <Route path = "/miniMenuMobileLogs" element = {<MenuMiniMobileLogs/>}/> 
              <Route path = "/miniMenuMobileBookingsAndCourses" element = {<MenuMiniMobileBookingsAndCourses/>}/>
            </Routes>
          {/* </ThemeProvider> */}
        </BrowserRouter>
      </AuthProviderFirebase>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render (<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();