// Import libraries
import { TimePickerEditor } from "../../utilities/dateAndTime/TimePicker";

// Import libraries


// Import CSS, images, icons & static data



// Import fonts



export const flightLogColumnsSim = [
    {
        field: 'id',
        headerName: 'Database Key',
        headerAlign: "left",
        align: "left",
        width: 180,
        editable: false,
        visibility: false,
    },
    {
        field: 'sortId',
        headerName: 'Sort Key',
        headerAlign: "left",
        align: "left",
        width: 180,
        editable: false,
        visibility: false,
        sort: "desc",
    },
    {
        field: 'invoice',
        headerName: 'Invoice',
        headerAlign: "left",
        align: "left",
        width: 160,
        editable: true,
        visibility: true,
    },
    {
        field: 'dateOfFlight',
        headerName: 'Date Of Flight',
        headerAlign: "left",
        align: "left",
        width: 160,
        type: 'date',
        editable: true,
    },
    {
        field: 'takeOff',
        headerName: 'Start Time',
        headerAlign: "left",
        align: "left",
        type: 'time',
        width: 130,
        editable: true,
        renderCell: (params) => <div>{params.value}</div>,
        renderEditCell: (params) => <TimePickerEditor {...params} />,
    },
    {
        field: 'landing',
        headerName: 'End Time',
        headerAlign: "left",
        align: "left",
        type: 'time',
        width: 130,
        editable: true,
        renderCell: (params) => <div>{params.value}</div>,
        renderEditCell: (params) => <TimePickerEditor {...params} />,
    },
    {
        field: 'flightTime',
        headerName: 'Total Time',
        headerAlign: "left",
        align: "left",
        type: "number",
        width: 150,
        editable: true,
    },
    {
        field: 'pilot',
        headerName: 'Pilot',
        headerAlign: "left",
        align: "left",
        width: 160,
        editable: true,
    },
    {
        field: 'passenger1',
        headerName: 'Instructor',
        headerAlign: "left",
        align: "left",
        width: 160,
        editable: true,
    },
    {
        field: 'passenger2',
        headerName: 'Organisation',
        headerAlign: "left",
        align: "left",
        width: 160,
        editable: true,
    },
    {
        field: 'flightDetails',
        headerName: 'Exercise Details',
        headerAlign: "left",
        align: "left",
        width: 360,
        editable: true,
    },
    {
        field: 'newDefect1',
        headerName: 'New Defect',
        headerAlign: "left",
        align: "left",
        width: 160,
        editable: true,
    },
    {
        field: 'newDefect2',
        headerName: 'New Defect',
        headerAlign: "left",
        align: "left",
        width: 160,
        editable: true,
    },
  ];


