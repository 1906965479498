// Import libraries
import {Box, TextField, Select } from "@mui/material";
import { FormControl, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
// import AdminCourseLessons from "./AdminCourseLessons.js";



// Import CSS, images, icons & static data
import "./Course.css"
import { aircraftComplexities } from "../aircraft/AircraftData";
import { aircraftTypes } from "../aircraft/AircraftData";
import { courseStatuses } from "../aircraft/AircraftData";
import { medicals } from "../aircraft/AircraftData";
import { mobileThreshold, mobileThresholdNarrow } from "../utilities/environment/EnvironmentData";
import { registeringAuthorities } from "../aircraft/AircraftData";
import courseIconSample from "./icons/courseIconSample.png"
import preRequisiteIcon from "./icons/preRequisitesIcon.png"







// ****************************** MAIN FUNCTION **********************************
export default function AdminCourseTable(props) {
    const [wideFieldClass, setWideFieldClass] = useState("course-field-wide");
    const [courseSummaryContainer, setCourseSummaryContainer] = useState("course-Summary-container");
    const [courseFieldsContainer, setCourseFieldsContainer] = useState("course-fields-container")
    const [courseIconClass, setCourseIconClass] = useState("course-icon-box");
    let iconURL = "";
    let preRequisiteIconURL = Object.values({preRequisiteIcon})[0];
    // const [selectedAircraft, setSelectedAircraft] = useState(props.selectedAircraft);
        // console.log("In AdminCourseTable, props.courseSubmission.courseIcon->", props.courseSubmission.courseIcon);

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    var mediaNarrow = useMediaQuery(`(min-width:${mobileThresholdNarrow}px)`);
    // var iconURL = Object.values(props.course.icon)[0];
    // var iconURL = props.courseSubmission.
    if (props.courseSubmission.courseIcon) {
        iconURL = Object.values(props.courseSubmission.courseIcon)[0];  
    } else {
        iconURL = Object.values({courseIconSample})[0];
    }



    useEffect(() => {
        if (media) {
            setCourseSummaryContainer("course-Summary-container")
            setCourseFieldsContainer("course-fields-container")
            setWideFieldClass("course-field-wide")
            setCourseIconClass("course-icon-box")
        } else {
            setCourseSummaryContainer("course-Summary-container-mobile")
            setCourseFieldsContainer("course-fields-container-mobile")
            setWideFieldClass("course-field-wide-mobile")
            setCourseIconClass("course-icon-box-mobile")
        }
    }, [media])
    
    
    let editing = props.editing;
  

        // console.log("In AdminCourseTable, props.courseSubmission->", props.courseSubmission)
  
    
    // maintenanceWindow = CalculateMaintenanceWindow(props.selectedAircraft);

    // useEffect(() => {
    //     if (props.selectedCourse.name === "NEW PLANE") {
    //         setNewPlane(true);            
    //     } else {
    //         setNewPlane(false)
    //     }
    // },[props.selectedAircraft.registration] )

    const registeringAuthorityList = registeringAuthorities.map((type) =>
    <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const statusList = courseStatuses.map((type) =>
    <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const aircraftComplexitiesList = aircraftComplexities.map((complexity) =>
        <MenuItem className = "select-options" selected = {false} key = {complexity} value = {complexity}>{complexity}</MenuItem>
    );

    const aircraftTypesList = aircraftTypes.map((type) =>
        <MenuItem className = "select-options" selected = {false} key = {type} value = {type}>{type}</MenuItem>
    );

    const medicalClassList = medicals.map((medicalClass) =>
        <MenuItem className = "select-options" selected = {false} key = {medicalClass} value = {medicalClass}>{medicalClass}</MenuItem>
    );

    return (
        <Box className = {courseSummaryContainer}>
            <Box className = {courseFieldsContainer}>
                <Box className = "course-subtitles">
                    Summary
                    <Box >
                        <img className = {courseIconClass} src = {`${iconURL}`}/>
                        {/* <Box sx = {{ m: "2px", fontSize: "small", color: "grey"}}> */}
                            {/* {props.courseSubmission.abbreviation.value} */}
                        {/* </Box> */}
                    </Box>
                </Box>
                {/* <Box> */}
                    <Box className= "course-field-row">
                        <TextField className = "course-field-wide" label = "Name"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.name.background} }}
                            type = "text" name = "name" id="name" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleCourseChange(e.target)} 
                            error = {props.courseSubmission.name.status === "error"}
                            variant = {props.courseSubmission.name.variant} 
                            value = {props.courseSubmission.name.value}
                            disabled = {editing === false}></TextField>
                        <TextField className = "course-field-narrow" label = "Abbreviation"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.abbreviation.background} }}
                            type = "text" name = "abbreviation" id="abbreviation" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleCourseChange(e.target)} 
                            error = {props.courseSubmission.abbreviation.status === "error"} 
                            variant = {props.courseSubmission.abbreviation.variant} 
                            value = {props.courseSubmission.abbreviation.value}
                            disabled = {editing === false}></TextField> 
                        <TextField className = "course-field-wide" label = "Description"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.description.background} }}
                            type = "text" name = "description" id="description" fullWidth multiline
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleCourseChange(e.target)} 
                            error = {props.courseSubmission.description.status === "error"} 
                            variant = {props.courseSubmission.description.variant} 
                            value = {props.courseSubmission.description.value}
                            disabled = {editing === false}></TextField> 
                    </Box>
                    <Box className = "course-field-row">
                        <FormControl>
                        <InputLabel>Aircraft Type</InputLabel>
                            <Select className = "course-select-field-narrow" label = "Aircraft Type" 
                                name = "planeType" id="planeType" fullWidth
                                // InputLabelProps={{ shrink: true }}
                                // InputProps = {{STYLE: props.courseSubmission.planeType.background }}
                                onChange={e => props.HandleCourseChange(e.target)} 
                                error = {props.courseSubmission.planeType.status === "error"} 
                                variant = {props.courseSubmission.planeType.variant} 
                                value = {props.courseSubmission.planeType.value}
                                disabled = {editing === false}>
                                {aircraftTypesList}
                            </Select> 
                        </FormControl>
                        <FormControl>
                        <InputLabel>Aircraft Complexity</InputLabel>
                            <Select className = "course-select-field" label = "Aircraft Complexity" 
                                name = "planeComplexity" id="planeComplexity" fullWidth
                                // InputLabelProps={{ shrink: true }}
                                // InputProps = {{STYLE: props.courseSubmission.planeComplexity.background }}
                                onChange={e => props.HandleCourseChange(e.target)} 
                                error = {props.courseSubmission.planeComplexity.status === "error"} 
                                variant = {props.courseSubmission.planeComplexity.variant} 
                                value = {props.courseSubmission.planeComplexity.value}
                                disabled = {editing === false}>
                                {aircraftComplexitiesList}
                            </Select> 
                        </FormControl>
                        <TextField className = "course-field-narrow" label = "Minimum Hrs"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.minimumHoursToComplete.background, min: 0} }}
                            type = "number" name = "minimumHoursToComplete" id="minimumHoursToComplete" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleCourseChange(e.target)} 
                            error = {props.courseSubmission.minimumHoursToComplete.status === "error"} 
                            variant = {props.courseSubmission.minimumHoursToComplete.variant} 
                            value = {props.courseSubmission.minimumHoursToComplete.value}
                            disabled = {editing === false}></TextField>
                    </Box>
                    {/* <Box className = "course-field-row">
                        <TextField className = "course-field-narrow" label = "Minimum Hrs"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.minimumHoursToComplete.background, min: 0} }}
                            type = "number" name = "minimumHoursToComplete" id="minimumHoursToComplete" fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleCourseChange(e.target)} 
                            error = {props.courseSubmission.minimumHoursToComplete.status === "error"} 
                            variant = {props.courseSubmission.minimumHoursToComplete.variant} 
                            value = {props.courseSubmission.minimumHoursToComplete.value}
                            disabled = {editing === false}></TextField> */}
                        {/* <TextField className = "course-field-wide" label = "Duration"
                            InputProps = {{ inputProps: {STYLE: props.courseSubmission.duration.background} }}
                            type = "text" name = "duration" id="duration" fullWidth multiline
                            InputLabelProps={{ shrink: true }}
                            onChange={e => props.HandleCourseChange(e.target)} 
                            error = {props.courseSubmission.duration.status === "error"} 
                            variant = {props.courseSubmission.duration.variant} 
                            value = {props.courseSubmission.duration.value}
                            disabled = {editing === false}></TextField>  */}
                        {/* </Box> */}
                {/* </Box> */}

            </Box>

           {/* **************************** SECTION ********************************** */}
            <Box className = {courseFieldsContainer}>
                <Box className = "course-subtitles">
                    Prerequisites
                    <img className = {courseIconClass} src = {`${preRequisiteIconURL}`}/>
                    {/* Object.values({courseIconSample})[0]; */}
                </Box>
                <Box className = "course-field-row">
                    <FormControl>
                    <InputLabel>Medical Class</InputLabel>
                        <Select className = "course-select-field" label = "Medical Class"
                            // InputProps = {{STYLE: props.courseSubmission.medicalClassRequired.background}}
                            // InputLabelProps={{ shrink: true }}
                            type = "text" name = "medicalClassRequired" id="medicalClassRequired" fullWidth
                            onChange={e => props.HandleCourseChange(e.target)} 
                            error = {props.courseSubmission.medicalClassRequired === "error"} 
                            variant = {props.courseSubmission.medicalClassRequired.variant} 
                            value = {props.courseSubmission.medicalClassRequired.value}
                            disabled = {editing === false}>
                            {medicalClassList}
                        </Select>
                    </FormControl>
                    <TextField className = "course-field-narrow" label = "Total Hours" 
                        InputProps = {{ inputProps: {STYLE: props.courseSubmission.prerequisiteHoursInAnAircraft.background, min: 0} }}
                        type = "number" name = "prerequisiteHoursInAnAircraft" id="prerequisiteHoursInAnAircraft" fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={e => props.HandleCourseChange(e.target)} 
                        error = {props.courseSubmission.prerequisiteHoursInAnAircraft.status === "error"} 
                        variant = {props.courseSubmission.prerequisiteHoursInAnAircraft.variant} 
                        value = {props.courseSubmission.prerequisiteHoursInAnAircraft.value}
                        disabled = {editing === false}></TextField> 
                    <TextField className = "course-field-narrow" label = "Hours as PIC" 
                        InputProps = {{ inputProps: {STYLE: props.courseSubmission.prerequisiteHoursAsPIC.background, min: 0, max: 5} }}
                        type = "number" name = "prerequisiteHoursAsPIC" id="prerequisiteHoursAsPIC" fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={e => props.HandleCourseChange(e.target)} 
                        error = {props.courseSubmission.prerequisiteHoursAsPIC.status === "error"} 
                        variant = {props.courseSubmission.prerequisiteHoursAsPIC.variant} 
                        value = {props.courseSubmission.prerequisiteHoursAsPIC.value}
                        disabled = {editing === false}></TextField> 
                    <TextField className = "course-field-narrow" label = "Hours in Type" 
                        InputProps = {{ inputProps: {STYLE: props.courseSubmission.prerequisiteHoursInType.background, min: 0, max: 5} }}
                        type = "number" name = "prerequisiteHoursInType" id="prerequisiteHoursInType" fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={e => props.HandleCourseChange(e.target)} 
                        error = {props.courseSubmission.prerequisiteHoursInType.status === "error"} 
                        variant = {props.courseSubmission.prerequisiteHoursInType.variant} 
                        value = {props.courseSubmission.prerequisiteHoursInType.value}
                        disabled = {editing === false}></TextField> 
                </Box>
            </Box>
        </Box> 
    );
}   