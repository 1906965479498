// Import libraries
import React, {useEffect, useState} from 'react';
import Button from '@mui/joy/Button';
import { GetOpenFlightList } from '../../../utilities/database/GetOpenFlightList';
import Box from '@mui/joy/Button';

// Import CSS & images
import shortArrow from "../images/shortArrow.png";
import "./OpenFlights.css";






// ******************************* MAIN FUNCTION ************************************************
export default function OpenFlights(props) {
        // console.log("In OpenFlights, defaultOpenFlightList -> ", defaultOpenFlightList, "props.selectedAircraft.registration ->", props.selectedAircraft.registration);
    
    const [flightList, setFlightList] = useState([])
    const [displayButtons, setDisplayButtons] = useState([])
    let displayButtonsTest = [];
    const [openFlightColour, setOpenFlightColour] = useState("primary")

    // *************************** POPULATE FORM BASED ON THE CLICKED OPEN FLIGHT *********************************
    const handleClick = (flight) => {
            // console.log("In OpenFlight, handleClick, flight->", flight);
        props.TechLogHandleClear()
            // console.log("UPDATE OPEN BUTTON TO FALSE");
        // props.UpdateOpenButton(false);
            // console.log("In OpenFlights, props.openButton->", props.openButton);

        
            // console.log("In OpenFlight, handleClick, registration->", props.selectedAircraft.registration)
            // console.log("In OpenFlight, handleClick, flight->", flight);
        let action = {
            type: "openFlight",
            flight: flight
        }
        props.TechLogOpenFlight(action)
    }
    // *************************** GET OPEN FLIGHTS FROM DATABASE BASED ON SELECTED REGISTRATION *********************************
    // const GetOpenFlights =  async (selectedAircraft) => {
    //     let responseObject = await GetOpenFlightList(selectedAircraft); 
    //         setFlightList(responseObject.data);
    //         props.UpdateDatabaseConnection(responseObject.databaseConnection);
    //             // console.log("In OpenFlights, GetFlightList, list(default)) -> ", list)
    //     }
    

    useEffect(() => {
        const GetOpenFlights =  async (selectedAircraft) => {
            let responseObject = await GetOpenFlightList(selectedAircraft); 
                setFlightList(responseObject.data);
                props.UpdateDatabaseConnection(responseObject.databaseConnection);
                // return responseObject;
                    // console.log("In OpenFlights, GetFlightList, list(default)) -> ", list)
        }
        GetOpenFlights(props.selectedAircraft);
            // console.log("In OpenFlights, props.selectedAircraft->", props.selectedAircraft)
            // console.log("In OpenFlights, flightList->", flightList)
    // eslint-disable-next-line react-hooks/exhaustive-deps


    
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.selectedAircraft._registration]);

useEffect(() => {
    let buttons = BuildOpenFlightButtons(flightList)
    setDisplayButtons(buttons);
    displayButtonsTest = buttons;
    // props.TechLogHandleClear()
        // console.log("In OpenFlights, useEffect, buttons->,", buttons,);
        // console.log("In OpenFlights, useEffect, flightList.length ->", flightList.length);
        // console.log("In OpenFlights, useEffect, displayButtons -> ", displayButtons);
        // console.log("In OpenFlights, useEffect, displayButtonsTest -> ", displayButtonsTest);

}, [flightList, props.selectedAircraft])
    

    // *************************** CREATE BUTTONS FOR EACH OPEN FLIGHT *********************************
    function BuildOpenFlightButtons(flightList) {
        let buttons = [];
            // console.log("In OpenFlights, flightList->", flightList);
        if (flightList.length !== {}) {
            let list = flightList
            buttons = list.map((flight) => 
                <Button className = "openFlightsButtons" 
                    variant = "outlined" 
                    key = {flight.dateOpened.value} 
                    color = {openFlightColour}
                    onClick = {(e) => handleClick(flight)}>
                        {flight.pilot.value} ({flight.dateOpened.value})
                </Button>
            );
        }
        return buttons
    }


    // *************************** RENDER OPEN FLIGHT BUTTONS *********************************
        // console.log("In OpenFlights, displayButtons.length->", displayButtons.length);
    if (displayButtons.length === 0) {
            // console.log("In OpenFlights, emptySubmission->", emptySubmission)
        return <span></span>;
    } else {
            // console.log("In OpenFlights, emptySubmission->", emptySubmission)
        return (
            <Box color = "transparent" className = "openFlightsLabel" sx = {{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                Open Flights
                <img src = {shortArrow} className = "openFlightsImage" alt = "Arrow"></img>
                {displayButtons}
            </Box>
        );
    }
    

};

