// Import libraries
import {Box, TextField} from "@mui/material";


// Import CSS, 
import "./Departure.css";

// ************************* MAIN FUNCTION ****************************
export default function TableCrewAndPAX(props) {

    let person1 = "Pilot Name*";
    let person2 = "Student or Passenger1";
    let person3 = "Student or Passenger2";

    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) !== -1) {
            person1 = "Student*";
            person2 = "Instructor*";
            person3 = "Organisation";
        } else {
            person1 = "Pilot Name*";
            person2 = "Student or Passenger1";
            person3 = "Student or Passenger2";
    }
    

    return (
        <Box className = "table1">
            <table>
                <tbody>
                    <tr>
                        <td className = "label">Date*</td>
                        <td className = "inputDate" colSpan ="1" STYLE = {props.submission.data.dateOfFlight.background}>
                            <TextField 
                                type = "date" format = "DD-MM-YYYY, HH:mm" name = "dateOfFlight" id = "dateOfFlight" timezone = "UTC" 
                                value = {props.submission.data.dateOfFlight.value}
                                label = {props.submission.data.dateOfFlight.status} 
                                color = {props.submission.data.dateOfFlight.status} 
                                error = {props.submission.data.dateOfFlight.status === "error"} 
                                variant = {props.submission.data.dateOfFlight.variant} 
                                fullWidth
                                onChange={e => props.TechLogHandleChange(e.target)}>
                            </TextField>
                        </td>    
                    </tr>
                    <tr>
                        <td className = "LR" colSpan = "1"></td>
                        <td className = "LR" colSpan = "1"></td>
                    </tr>
                    <tr>
                        <td className = "labelLong" colSpan = "1">{person1}</td>
                        <td className = "inputWide" colSpan ="1" STYLE = {props.submission.data.pilot.background}>
                            <TextField 
                                type = "text" name = "pilot" id = "pilot" 
                                value = {props.submission.data.pilot.value} 
                                label = {props.submission.data.pilot.required} 
                                error = {props.submission.data.pilot.status === "error"} 
                                variant = {props.submission.data.pilot.variant} 
                                fullWidth
                                onChange = {e => props.TechLogHandleChange(e.target)}>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td className = "labelLong">{person2}</td>
                        <td className = "inputWide" colSpan = "1" STYLE = {props.submission.data.passenger1.background}>
                            <TextField 
                                // InputProps={{inputProps: {style : props.submission.passenger1.background}}}
                                type = "text" name = "passenger1" id = "passenger1"
                                value = {props.submission.data.passenger1.value} 
                                label = {props.submission.data.passenger1.required} 
                                fullWidth 
                                error = {props.submission.data.passenger1.status === "error"} 
                                variant = {props.submission.data.passenger1.variant}  
                                onChange = {e => props.TechLogHandleChange(e.target)}>
                            </TextField>
                        </td>
                    </tr>
                    <tr>    
                        <td className = "labelLong">{person3}</td>
                        <td className = "inputWide"  colSpan = "1" STYLE = {props.submission.data.passenger2.background}>
                            <TextField 
                                type = "text" name = "passenger2" id = "passenger2"
                                value = {props.submission.data.passenger2.value} 
                                label = {props.submission.data.passenger2.required} 
                                fullWidth 
                                error = {props.submission.data.passenger2.status === "error"} 
                                variant = {props.submission.data.passenger2.variant}  
                                onChange = {e => props.TechLogHandleChange(e.target)}>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td className = "note" colSpan ="1">* Mandatory Fields</td>
                        <td className = "blank" colSpan = "1"></td>
                    </tr>
                </tbody>
            </table>
        </Box>
    )

}