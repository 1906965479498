// *************** IMPORT LIBRARIES *****************
import { emptyAircraftSubmission } from "../aircraft/AircraftData.js";
import dayjs from "dayjs";
import SubmissionFieldReset from "./SubmissionFieldReset.js";
import MarkErrorsAndWarnings from "./MarkErrorsAndWarnings.js";
import _ from "lodash";

// ***************** IMPORT STATIC DATA **************
import { successMessages } from "../utilities/validation/AircraftValidations.js";


// ************** MAIN FUNCTION *****************

function AircraftFormReducer(aircraftSubmission, action) {
        // console.log("In AircraftFormReducer-START, submission->", submission)
        // console.log("In AircraftFormReducer-START, action->", action)
    let field = action.name
        // console.log("In Reducer, aircraftSubmission -> ", aircraftSubmission, "& action.field -> ", field);
    switch (action.type) {   
    case 'clear':
        aircraftSubmission = emptyAircraftSubmission;
            // console.log("In AircraftFormReducer, CLEAR, submission->", submission);
        Object.keys(emptyAircraftSubmission).forEach((field) => {
            aircraftSubmission[field].value = emptyAircraftSubmission[field].value;
        });
            // console.log("In Reducer, Clear-submission->", submission);
        return {
            ...aircraftSubmission,
            mostSevereIssue: {
                status: "info",
                message: successMessages.clear
            }
        };

    case 'clearAuto':
        aircraftSubmission = emptyAircraftSubmission;
        Object.keys(emptyAircraftSubmission).forEach((field) => {
            aircraftSubmission[field].value = emptyAircraftSubmission[field].value;
        });
            // console.log("In Reducer, ClearAuto-submission->", submission);
        return {
            ...aircraftSubmission

            };
    case "update":
        // Set submission object to the current selected aircraft
        var planeSubmission = {};
        var field2
            // console.log("In updateAircraftSubmission, action.selectedAircraft->", action.selectedAircraft)
        if (action.selectedAircraft !== undefined) {
            // if (action.selectedAircraft._registration !== "PLANE") {
            planeSubmission = emptyAircraftSubmission
            Object.keys(action.selectedAircraft).forEach((field) => {
                field2 = field.slice(1,field.length);
                    // console.log("In aircraftReducer, field->", field, "field2->",field2)
                switch(field) {
                    case "_oilLevels":
                        let i = 0;
                        action.selectedAircraft[field].forEach((oilLevel) => {
                            planeSubmission.oilLevels[i].value = oilLevel;   
                            i++
                        })
                    break;
                    case "_defects":
                        planeSubmission.defects = action.selectedAircraft._defects
                            // console.log("In UpdateAircraftSubmission, defects->", aircraftSubmission.defects)
                    break;
                    case "_colour":
                        planeSubmission.colour.value = action.selectedAircraft._colour
                            // console.log("In UpdateAircraftSubmission, aircraftSubmission->", aircraftSubmission)
                    break;
                    case "_dateOfNextService":
                            // console.log("In AircraftReducer, dayjs(action.selectedAircraft._dateOfNextService->)", dayjs(action.selectedAircraft._dateOfNextService));
                        planeSubmission.dateOfNextService.value = dayjs(action.selectedAircraft._dateOfNextService);
                    break;
                    default: 
                            // console.log("In UpdateAircraftSubmission, planeSubmission[field2]->", planeSubmission[field2],"field2->", field2)
                            // console.log("In UpdateAircraftSubmission, aircraftSubmission->", aircraftSubmission)
                            // console.log("In aircraftReducer, aircraftSubmission[field2]->", aircraftSubmission[field2], "action.selectedAircraft[field]->",action.selectedAircraft[field])
                        planeSubmission[field2].value = action.selectedAircraft[field];
                }
            });
        }

        aircraftSubmission = planeSubmission;

        return {
            ...aircraftSubmission
        }

    case 'edit':
            // console.log("In AircraftFormReducer, field ->", field, "& action ->", action);
            // console.log("In AircraftFormReducer, edit, submission[field] ->", submission[field]);
            // console.log("In AircraftFormReducer, edit, submission ->", submission);
            // console.log("In AircraftFormReducer, edit, submission ->", emptySubmission);
        
        if (action === null) {
            // emptySubmission = blankSubmission;
            return;
        } else {
                // console.log("In AircraftFormReducer, action.name->", action.name)
                // console.log("In AircraftFormReducer, action.name.indexOf(oilLevel)->", (action.name.indexOf("oilLevel") !== -1))
            
            if (action.name.indexOf("oilLevel") !== -1) {
                    // console.log("In AircraftReducer, field->", field, "action.value->", action.value)
                    // let field2 = field.slice(1,-2);
                    let field3 = field.slice(-1);
                    // let field4 = field2[field3];
                    let field5 = `oilLevel[${field3}]`
                        // console.log("In AircraftReducer, field5->", field5)
                    let newObject = {
                        value: action.value,
                        status: aircraftSubmission.oilLevels[field3].status,
                        background: aircraftSubmission.oilLevels[field3].background,
                        variant: aircraftSubmission.oilLevels[field3].variant,
                        reset: aircraftSubmission.oilLevels[field3].reset,
                        required: aircraftSubmission.oilLevels[field3].required
                    }
                    aircraftSubmission.oilLevels[field3] = newObject
                    return {
                    ...aircraftSubmission,
                    }
            } else {
                    // console.log("In AircraftFormReducer, action.name->", action.name)
                switch(action.name) {
                case "dateOfNextService":
                        // console.log("In AircraftReducer, action->", action);
                    action.value = dayjs(action.value)
                       
                    return {
                        ...aircraftSubmission,
                        [field]: {
                            value: action.value,
                            status: aircraftSubmission[field].status,
                            background: aircraftSubmission[field].background,
                            variant: aircraftSubmission[field].variant,
                            reset: aircraftSubmission[field].reset,
                            required: aircraftSubmission[field].required
                        }
                    };
                case "defectCheckbox":
                            // console.log("In AircraftFormReducer, defectCheckBox, action->", action);
                        aircraftSubmission.defects.forEach((defect) => {
                                // console.log("In AircraftFormReducer, defectCheckBox, aircraftSubmission.defects.forEach(defect)->", defect, "action->", action);
                            if (defect.id.toString() === action.id) {
                                    // console.log("In AircraftFormReducer, defectCheckBox, defect.id && action.id->", defect.id.toString(), " = ", action.id, (defect.id.toString() === action.id));
                                    // console.log("In AircraftFormReducer, defectCheckBox, defect.status & action.status->", defect.defectStatus, action.status);
                                    // console.log("In AircraftFormReducer, defectCheckBox, action.event.checked->", action.event.checked);
                                if (action.event.checked) {
                                    defect.defectStatus = "CLOSED";
                                    defect.dateClosed = dayjs(new Date()).format("YYYY-MM-DD");
                                        // console.log("In AircraftFormReducer, defect->", defect);
                                } else {
                                    defect.defectStatus = "OPEN";
                                    defect.dateClosed = "";
                                }
                            }
                        })
                    return {
                        ...aircraftSubmission,
                        // [field]: {
                        //     value: action.value,
                        //     status: aircraftSubmission[field].status,
                        //     background: aircraftSubmission[field].background,
                        //     variant: aircraftSubmission[field].variant,
                        //     reset: aircraftSubmission[field].reset,
                        //     required: aircraftSubmission[field].required
                        // }
                    }
                default: 
                    return {
                        ...aircraftSubmission,
                        [field]: {
                            value: action.value,
                            status: aircraftSubmission[field].status,
                            background: aircraftSubmission[field].background,
                            variant: aircraftSubmission[field].variant,
                            reset: aircraftSubmission[field].reset,
                            required: aircraftSubmission[field].required
                        }
                    };
                }
            }
        }
        
    

    case "updateAircraft":
    case "newAircraft":
            // console.log("In Reducer, Open/ Submit, submission -> ", submission);
            // console.log("In AircraftFormReducer, reset aircraftSubmission->", aircraftSubmission);
            let submission = {}
            submission.data = _.clone(aircraftSubmission);
        submission = SubmissionFieldReset(submission, "aircraftAdmin");
        let notSim = true;
        if (submission.data.planeType.value === "SIM") {
            notSim = false;
        } else {
            notSim = true;
        }

        submission.data.oilLevels.forEach((level) => {
            level.required = notSim;
        })
        submission.data.fuelTank1Capacity.required = notSim;
        submission.data.fuelTank2Capacity.required = notSim;
        submission.data.fuelTank3Capacity.required = notSim;
        submission.data.fuelTank4Capacity.required = notSim;
        submission.data.fuelTank1State.required = notSim;
        submission.data.fuelTank2State.required = notSim;
        submission.data.fuelTank3State.required = notSim;
        submission.data.fuelTank4State.required = notSim;
        submission.data.dateOfNextService.required = notSim;
        submission.data.nextServiceHours.required = notSim;
        submission.data.currentHours.required = notSim;
        

        // Update the status of each field with an issue 
        aircraftSubmission = MarkErrorsAndWarnings(submission.data, action.validationResultList, field, "aircraftAdmin");
            // console.log("In AircraftFormReducer, MarkErrors, aircraftSubmission->", aircraftSubmission);
            // console.log("In AircraftReducer, aircraftSubmission->", aircraftSubmission);
        return aircraftSubmission;
    default: console.log("Unknown AircraftAdmin Action, action->", action);
}};

export default AircraftFormReducer;