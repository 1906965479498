// Import libraries
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import {ClickAwayListener, Popper} from '@mui/material';
import { Box } from '@mui/system';
import { HasPermission } from '../utilities/security/HasPermission';

// Import icons
import MenuIcon from '@mui/icons-material/Menu';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

// ******************************* MAIN FUNCTION ************************************************
export default function MenuMiniMobileBookingsAndLessons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [anchorRef, setAnchorRef] = React.useRef(null);
  const open = Boolean(anchorEl);
    
  var currentUserRoles = props.currentUserRoles;
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 
  const handleClose = (path) => {
    setAnchorEl(null);
    props.handleNavigate(path)
  };

  return (
    <Box>
        <EventAvailableIcon
          id = "mobileMenuBookingsAndLessons"
          disabled 
          onClick = {handleClick}
          color = "inherit" 
          sx = {{marginTop: '15px', marginLeft: "30px", verticalAlign: "middle"}}
        >
        </EventAvailableIcon>
        <Menu
          id="menuMiniMobileBookingsAndCourses"
          // MenuListProps={{'aria-labelledby': 'mobileMenuIcon'}}
         
          //  disabled = {!HasPermission("bookings", currentUserRoles) && !HasPermission("lessons", currentUserRoles)} 
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}

        >
          {/* <ClickAwayListener onClickAway={handleClose}> */}
            <MenuItem 
              disabled = {!HasPermission("bookings", currentUserRoles)} 
              // disabled = {!HasPermission("bookings", currentUserRoles)} 
              onClick = {() => handleClose("/bookings")}>
                Bookings
            </MenuItem>
            <MenuItem 
              disabled = {!HasPermission("courseSelection", currentUserRoles)} 
              // disabled = {!HasPermission("lessons", currentUserRoles)} 
              onClick = {() => handleClose("/courseSelection")}>
                Courses
              </MenuItem>
          {/* </ClickAwayListener> */}
      </Menu>
    </Box>
  );
};