import React, {useState, useEffect, useReducer} from 'react';
import Box from '@mui/material/Box';
import _ from "lodash";
import { CustomGridToolBar } from '../../utilities/CustomGridToolBar.js';
import { styled } from '@mui/material';
import { useAuthFirebase } from '../../utilities/security/AuthContextFirebase.js';
import { GetUserRoles } from '../../utilities/security/AuthUtilities.js';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';



// Import CSS, icons, images & static data
import "./FlightLog.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { columnGroupingModel } from './FlightLogColumnGroupingModel.js';
import { columnGroupingModelSim } from './FlightLogColumnGroupingModelSim.js';
import azTechTheme from '../../utilities/environment/ColourTheme.js';
import { flightValidationMessages } from '../../utilities/validation/FlightValidationMessages.js';
import { rolesTemplate } from '../../utilities/database/GetRolePermissions.js';



// ************************** MAIN FUNCTION ***********************************

export function FlightLogGrid(props) {
    let submission = props.submission;
    let selectedAircraft = props.selectedAircraft;
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState(props.gridRows);

    const {authUser, loading, signInWithGoogle, signOut} = useAuthFirebase();
    const [currentUser, setCurrentUser] = useState("");
    const [currentUserRoles, setCurrentUserRoles] = useState(rolesTemplate);
    let responseObject = {};
    let columnGrouping = [];
    let calledFrom = "PilotLog";
    const [editing, setEditing] = useState(false); 

    useEffect (() => {
        authUser ? setCurrentUser(authUser.displayName) : setCurrentUser("");
        UpdateRoles();
    }, [authUser]); 
  
    const UpdateRoles = async () => {
        responseObject = await GetUserRoles();
        if (responseObject.databaseConnection) {
                // console.log("In FlightLogGrid, responseObject->", responseObject)
            if (!responseObject.data.admin.status && !responseObject.data.schoolAdmin.status) {
                setEditing(false)
                    // console.log("In FlightLogGrid, editing = false")
            } else {
                setEditing(true)
                    // console.log("In FlightLogGrid, editing = true")
            }
            setCurrentUserRoles(responseObject.data);
            props.UpdateDatabaseConnection(responseObject.databaseConnection);
        } else {
            props.UpdateDatabaseConnection(responseObject.databaseConnection);
            props.UpdateResult(responseObject.data);

        }
    }

    if (selectedAircraft._planeType === "SIM") {
        columnGrouping = _.cloneDeep(columnGroupingModelSim)
    } else {
        columnGrouping = _.cloneDeep(columnGroupingModel)
    }
        

    // useEffect(() => {

    //   }
    //     console.log("In PilotSelect, currentUserRoles", currentUserRoles);
    //     console.log("In FlightLogGrid, currentUserRoles.admin.status->", currentUserRoles.admin.status, "currentUserRoles.schoolAdmin.status->", currentUserRoles.schoolAdmin.status);
    //   if (!currentUserRoles.admin.status && !currentUserRoles.schoolAdmin.status) {
    //     setEditing(false)
    // } else {
    //     setEditing(true)
    // }


    // }, [currentUser])


   

    useEffect(() => {
        setRows(props.gridRows);
            // console.log("In FlightLogGrid, useEffect, rows->", rows)
    }, [props.gridRows])


    // ******************** BUTTON FUNCTIONS ********************************
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });    
    };

    const handleDeleteClick = (id) => async () => {

        let rowForUpdate = rows.find((row) => row.id === id);
            // console.log("In handleDeleteClick, id->", id);

        let mostSevereIssue = {
            status: "warning",
            message: flightValidationMessages.warningMessages.delete,
            context: rowForUpdate,
            action: "delete"
        }
            // console.log("In FlightLogGrid, mostSevereIssue->", mostSevereIssue);
        props.UpdateResult(mostSevereIssue);
        props.UpdateDialogueStatus(true);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
            // setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow, id) => {
            // console.log("In FlightLogGrid, processRowUpdate, newRow->",newRow)
        const editedRow = rows.find((row) => row.id === id);
        const updatedRow = { ...newRow, isNew: false };

        let rowForUpdate = props.PackageRow(updatedRow, selectedAircraft, "update");
            // console.log("In FlightLogGrid, rowForUpdate->", rowForUpdate);
        let submission = await props.ManageFlightLogSubmission("update", rowForUpdate);
            // console.log("In FlightLogGrid, validated submission->", submission);

        // If the save isn't successful (due ot invalid data or database connection issue), do nothing 
        if (submission.mostSevereIssue.status === "error") {
            props.UpdateSnackBarStatus(true);
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            return editedRow;
        } else {
            // props.UpdateResult(submission.mostSevereIssue);
            if (submission.mostSevereIssue.status === "warning") {
                submission.mostSevereIssue.action = "save"
                submission.mostSevereIssue.context = submission.data;
                props.UpdateResult(submission.mostSevereIssue)
                props.UpdateDialogueStatus(true);
                return editedRow;
            } else {
                submission = await props.ManageFlightLogSubmission("save", submission);
                    // console.log("In FlightLogGrid, saved submission->", submission);
                props.UpdateResult(submission.mostSevereIssue);
                props.UpdateSnackBarStatus(true);
                setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                return updatedRow;
            }
        }
    };

    const handleRowModesModelChange = (newRowModesModel) => {
            // console.log("In FlightLogGrid, handleRowModesModelChange->", newRowModesModel);
        setRowModesModel(newRowModesModel);
    };


    // ******************** BUILD COLUMN HEADERS ********************************

    const FlightLogDataGrid = styled(DataGrid)(({theme}) => ({

        ".action": {
        color: azTechTheme.palette.mainBlue,
        // color: "red",
        fontWeight: "bolder",
        headerAlign: "left"
        },
    })) 

    let editColumns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTIONS',
            headerClassName: 'action',
            width: 120,
            headerAlign: "left",
            align: "left",
            cellClassName: 'action',
            color: 'primary.main',
            fontWeight: "bold",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            className="action"
                            sx={{
                            color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id, submission)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            // className="textPrimary"
                            className="action"
                            onClick={handleCancelClick(id)}
                            sx={{
                                color: 'primary.main',
                            }}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        disabled = {editing === false}
                        className = "action"
                        onClick = {handleEditClick(id)}
                        sx={{
                            color: 'primary.main',
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        disabled = {editing === false}
                        className = "action"
                        onClick = {handleDeleteClick(id)}
                        sx={{
                            color: 'primary.main',
                        }}
                    />,
                ];
            },
        },
    ]

    props.columns.forEach((column) => {
        editColumns.push(column)
    })
    let displayColumns = editColumns
       

    // ******************** RENDER DATA GRID ********************************
    return (
        <Box
            sx={{
            height: 500,
            width: '100%',
            '& .actions': {
                color: 'text.secondary',
            },
            '& .textPrimary': {
                color: 'text.primary',
            },
            }}
        >
            <DataGrid
            color = "info"
            initialState={{
                pinnedColumns: {
                    left: ["id", "sortId", "actions", "invoice", "dateOfFlight"],
                },
                sorting: {
                    sortModel: [{ field: 'sortId', sort: 'desc' }],
                },
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        invoiceNumber: false,
                        sortId: false
                    },
                },
                pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                },

            }}
            experimentalFeatures = {{ columnGrouping: true }}
            columnGroupingModel = {columnGrouping}
            columnGrouping = {columnGroupingModel}
            pageSizeOptions = {[100]}
            rows = {rows}
            columns = {displayColumns}
            editMode = "row"
            rowModesModel = {rowModesModel}
            onRowModesModelChange = {handleRowModesModelChange}
            // onCellEditStop = {handleCellEditStop}
            onRowEditStop = {handleRowEditStop}
            processRowUpdate = {processRowUpdate}
            slots = {{
                toolbar: CustomGridToolBar
            }}
            slotProps = {{
                // toolbar: { setRows, setRowModesModel, rows, selectedAircraft},
                toolbar: { setRows, setRowModesModel},
            }}
            />
        </Box>
    );
}