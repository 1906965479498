// Import libraries and css
import { Button, Box } from "@mui/material";
import { useEffect } from "react";
import TableCrewAndPAX from "./TableCrewAndPAX.js";
import TableDepartureFuel from "./TableDepartureFuel.js";
import TableOil from "./TableOil.js";
import { useMediaQuery } from "@mui/material"; 
import AircraftHeader from "../../../utilities/AircraftHeader.js";
import Media from "../../../utilities/environment/Media.js";
import BannerMaintenanceRow from "../banner/BannerMaintenanceRow.js";
 

// Import TechLogHandleClickOpen from "../../dispatchersAndReducers/TechLogFormReducer.js"
// import Flight from "../flightData/FlightData.js";

// Import CSS, images, icons & static data
import "./Departure.css";
import DepartureImage from "./DepartureImage.js";
import { mobileThresholdWide } from "../../../utilities/environment/EnvironmentData.js";




// ******************************* MAIN FUNCTION ************************************************
export default function Departure(props) {

        // console.log("In DepartureMobile, props.submission -> ", props.submission)

var departureFlexStyle;
var mediaWide = useMediaQuery(`(min-width:${mobileThresholdWide}px)`);

useEffect(() => {
    if (mediaWide) {
        departureFlexStyle = "spaceAround";
            
    } else {
        departureFlexStyle = "flexStart";
         
    }
        // console.log("In Departure, mediaWide->", mediaWide, "departureFlexStyle->", departureFlexStyle)

}, [mediaWide]);



    const DepartureOpen = () => {
            // console.log("In Departure, DepartureOpen, props.submission->", props.submission)
        let action = {
            type: "edit",
            name: "registration",
            value: props.registration
        }

        props.TechLogHandleChange(action)

        action = {
            type: "edit",
            name: "dateOpened",
            value: props.submission.data.dateOfFlight.value
        }
        props.TechLogHandleChange(action)
        
            // console.log("In Departure, OPEN button pressed, props.submission -> ", props.submission)

        props.TechLogHandleOpenSubmit("departure");
            // console.log("In Departure, techLogHandleOpen called, props.submission -> ", props.submission)
    };

    // Manage changes in aircraft selection
    useEffect(() => {
        let action = {
            name: "registration",
            value: props.registration
        } 
        props.TechLogHandleChange(action)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.registration]);

    // Establish media state based on width of screen

    var mobile = Media();
        // console.log("In Departure, dep-departure$mobile -> ", `departure${mobile}`)
        // console.log("In Departure, dep-details$mobile -> ", `dep-details${mobile}`);
 
    return (
        <Box className = {`departure${mobile}`}>
            <DepartureImage TechLogHandleChange = {props.TechLogHandleChange} registration = {props.registration}/>
            <Box className = {`dep-details${mobile}`}>
                <Box className = "dep-details-row1">
                    <AircraftHeader TechLogHandleChange = {props.TechLogHandleChange} registration = {props.registration}/>
                </Box>
                <Box className = "dep-details-row2" sx= {{display: "flex", flexDirection: 'row', justifyContent: {departureFlexStyle}, flexWrap: 'wrap'}}>
                    <div className = "table-crewAndPAX"><TableCrewAndPAX submission = {props.submission} TechLogHandleChange = {props.TechLogHandleChange} /></div>
                    <div className = "table-departureFuel"><TableDepartureFuel submission = {props.submission} selectedAircraft = {props.selectedAircraft} TechLogHandleChange = {props.TechLogHandleChange}/></div>
                    <div className = "table-Oil"><TableOil submission = {props.submission} TechLogHandleChange = {props.TechLogHandleChange} selectedAircraft = {props.selectedAircraft}/></div>
                </Box>
                <Box className = "dep-details-row3">
                    <Button className = "buttonOpen" disabled = {props.openButton === false} size = "medium" variant = "contained" name = "flightOpen" onClick={DepartureOpen}>OPEN</Button>
                </Box>
           </Box>
        </Box>
    );
}