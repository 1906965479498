// Import libraries
import React, {useState, useEffect, useReducer} from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import {Box} from "@mui/material";
import Banner from "./banner/Banner.js"
import Departure from './departure/Departure.js';
import Arrival from './arrival/Arrival.js';
import AwayLandings from "./awayLandings/AwayLandings.js"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {emptySubmission, emptySubmissionSim} from "../flightData/FlightData.js";
import TechLogFormReducer from "../../dispatchersAndReducers/TechLogFormReducer.js";
import OpenFlights from "./openFlights/OpenFlights.js";
import Feedback from '../../utilities/feedback/Feedback.js';
import Validate from '../../utilities/validation/Validate.js';
import { PostFlight } from '../../utilities/database/PostFlight.js';
import { GetAircraftData } from '../../utilities/database/GetAircraftData.js';
import _ from "lodash";


// Import fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import CSS, images, icons & static data
import './TechLog.css';
import PrefillTechLog from './PrefillTechLog.js';
import {defaultAircraftList} from '../../aircraft/AircraftData.js';
import { flightValidationMessages } from '../../utilities/validation/FlightValidationMessages.js';
import { databaseConnectionMessages } from '../../utilities/database/DatabaseConnectionMessages.js';
import { background } from '../flightData/FlightData.js';
import { GetLatestFlight } from '../../utilities/database/GetLatestFlight.js';
import { Update } from '@mui/icons-material';
// import { s } from '@fullcalendar/core/internal-common.js';

// ******************************* MAIN FUNCTION ************************************************
function TechLog(props) {
    // Initialise context using default variables
    const title = "Tech Log";
    const aircraftSelectionKey = 0
   

    // State - Aircraft, Service Events, Selected Aircraft, Registration, DB Connection & Open Button
    const [aircraftList, setAircraftList] = useState(defaultAircraftList);
    const [selectedAircraft, setSelectedAircraft] = useState(aircraftList[aircraftSelectionKey]);
    const [registration, setRegistration] = useState("");
    const [databaseConnection, setDatabaseConnection] = useState(true);
    const [dialogueResponse, setDialogueResponse] = useState(false);
    const [serviceEventList, setServiceEventList] = useState(false);
    const [openButton, setOpenButton] = useState(true);
    const [deleteButton, setDeleteButton] = useState(false);
    const [calledFrom, setCalledFrom] = useState("TechLog");
    
    // Validations & SnackBar
    const [result, setResult] = useState({status: "", message: "" })
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [dialogueStatus, setDialogueStatus] = useState(false);
   

    // Initialise the most severe issue container 
    let mostSevereIssue = {
        status: "success",
        message: flightValidationMessages.successMessages.departure,
        field1: "",
        field2: "",
        field3: "",
        field4: ""
    };

    dayjs.extend(utc);

    // Snackbar & Dialogue Update functions
    function UpdateSnackBarStatus (newStatus) {
            // console.log("In TechLog, UpdateSnackBarStatus, newStatus->", newStatus);
        setSnackBarStatus(newStatus);
            // console.log("In TechLog, UpdateSnackBarStatus, snackBarStatus->", snackBarStatus);
    }
    function UpdateDialogueStatus (newStatus) {
            // console.log("In TechLog, UpdateDialogueStatus, newStatus->", newStatus);
        setDialogueStatus(newStatus);
            // console.log("In TechLog, UpdateDialogueStatus, DialogueStatus->", DialogueStatus);
    }
    function UpdateDialogueResponse (response) {
            // console.log("In TechLog, UpdateDialogueResponse, response->", response);
        setDialogueResponse(response);
            // console.log("In TechLog, UpdateDialogueResponse, DialogueStatus->", dialogueResponse);
    }
    // function UpdateOpenButton (newStatus) {
    //     setOpenButton(newStatus);
    //         console.log("UPDATE OPEN BUTTON TO ...", newStatus);
    //         console.log("In TechLog, openButton->", openButton);
    // }
    function UpdateDatabaseConnection (response) {
        setDatabaseConnection(response);
    }
    // Update Validation result functions
    function UpdateResult (newResult) {
        // console.log("In TechLog, UpdateResult, newResult -> ", newResult)
        if (newResult !== undefined) {
            setResult(newResult);
        }
            // console.log("In TechLog, UpdateResult, result -> ", result)
    }
    
    // Update Registration functions for other components
    async function UpdateSelection (selection, planeList) {
            // console.log("In TechLog, Update, selection->", selection)
        let currentSvc = 0
        let responseObject = {
            data: {},
            databaseConnection: true
        }
        setRegistration(selection);
        // TechLogHandleClearAuto();
        if (planeList._registration === "NEW PLANE") {
            planeList._registration = "SELECT"
        }


        // console.log("In TechLog, responseObject.data->", responseObject.data);
        // console.log("In TechLog, currentSvc->", currentSvc);
        // console.log("In TechLog, planeList->", planeList);

        if (planeList != null) {
            planeList.forEach((plane) => {
                if (plane._registration === selection) {
                    setSelectedAircraft(plane);
                
                        // console.log("In TechLog, Update, plane->", plane);
                    if (plane._planeType === "SIM") {
                        // setOpenButton(false);
                            // console.log("In TechLog, SIM openButton->", openButton);
                    }
                }
            });
            
        }

        if (selection !== "SELECT" && selection !== null) {
            let responseObject = await GetLatestFlight(selection);
                // console.log("In techLog, responseObject->", responseObject);
            if (responseObject.databaseConnection) {
                currentSvc = responseObject.data.svcOn;
                PrefillTechLog(TechLogHandleChange, TechLogHandleOilChange, selectedAircraft, currentSvc);
            } else {
                mostSevereIssue = {
                    status: "error",
                    message: databaseConnectionMessages.error
                };
                UpdateResult(mostSevereIssue);
                UpdateSnackBarStatus(true);
            }
        }


            // console.log("In TechLog, Registration Update, submission ->", submission);
    }

    // Update aircraft selection based on user choice of registration
    useEffect(() => {

        TechLogHandleClearAuto();
        UpdateSelection(registration, aircraftList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aircraftList]);


    const PlaneData = async () => {
        let responseObject = await GetAircraftData()
            // console.log("In AdminAircraftMain, responseObject->", responseObject);
        if (responseObject.databaseConnection) {
            // UpdateSelection(responseObject.data.aircraftList[0]._registration, responseObject.data.aircraftList);
            setDatabaseConnection(responseObject.databaseConnection);
            setAircraftList(responseObject.data.aircraftList);
            setServiceEventList(responseObject.data.serviceEventList)
        }
    }

    useEffect(() => {

        PlaneData();
        
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [databaseConnection]);

        

    // *********************** TECH LOG REDUCER FOR MANAGING THE FLIGHT SUBMISSION OBJECT ****************************
    let submissionTemplate = _.cloneDeep(emptySubmission)
        // console.log("In TechLog, selectedAircraft._planeType->", selectedAircraft._planeType);
    if (selectedAircraft._planeType === "SIM") {
        submissionTemplate = _.cloneDeep(emptySubmissionSim);
    }
    const [submission, dispatch] = useReducer(TechLogFormReducer, submissionTemplate);
            // console.log("In TechLog, post initialisation of submission->", submission)
        if (selectedAircraft._registration === "GFOOJ") {
            submission.data.svcOff.requiredSubmit = false;
            submission.data.svcOn.requiredSubmit = false;
        }
        // console.log("In TechLog, post initialisation of submission, dayjs(new Date()).format(YYYY-MM-DD, HH:mm)->", dayjs(new Date()).format("YYYY-MM-DD, HH:mm"));
    
        useEffect(()=>{
                // console.log("In TechLog, submission.data.status.value->", submission.data.status.value)
            if (submission.data.status.value === "OPEN" || selectedAircraft._planeType === "SIM") {
                setOpenButton(false)
                setDeleteButton(false)
            } else {
                setOpenButton(true)
                setDeleteButton(false)
            }
    
        },[submission])

    // Handle Change Text
    const TechLogHandleChange = (e) => {
        if (e !== undefined) {
                
            if (e.type === "checkbox") {
                    // console.log("In TechLogHandleChange, e->",e," typeof(e.value)-> ");
                dispatch({
                    type: 'edit',
                    fieldType: e.type,
                    name: e.name,
                    value: e.value,
                    status: e.value,
                    event: e
                })
            } else {
                    // console.log("In TechLog, edit, e.name->", e.name, "e.value->",e.value, "submission->", submission)
                dispatch({
                    type: 'edit',
                    name: e.name,
                    value: e.value,
                })
            }
        }
        // console.log("In TechLogHandleChange, submission->", submission);
    }

    const TechLogHandleOilChange = (action) => {
        dispatch(action);
    }

    // Handle Change
    const TechLogHandleChangeNumber = (e) => {
        if (e !== undefined) {
                // console.log("In TechLogHandleChange, typeof(e.value) -> ", typeof(e.value))
            dispatch({
                type: 'edit',
                name: e.name,
                value: Number(e.value),
            })
        }
    }

    // Handle Clear Auto
    const TechLogHandleClearAuto = async () => {
            // console.log("Calling TechLogHandleClearAuto");
        dispatch({
            type: 'clearAuto',
            numberOfEngines: selectedAircraft._numberOfEngines
        })
        let selection = selectedAircraft._registration
        let currentSvc = 0
        // Update the submission form with departure fuel and svcOff from the selected aircraft
        setRegistration(aircraftList[0]._registration);
        if (selection !== "SELECT" && selection !== null) {
            let responseObject = await GetLatestFlight(selection);
                // console.log("In techLog, responseObject->", responseObject);
            if (responseObject.databaseConnection) {
                currentSvc = responseObject.data.svcOn;
                PrefillTechLog(TechLogHandleChange, TechLogHandleOilChange, selectedAircraft, currentSvc);
            } else {
                mostSevereIssue = {
                    status: "error",
                    message: databaseConnectionMessages.error
                };
                UpdateResult(mostSevereIssue);
                UpdateSnackBarStatus(true);
            }
        }
        // PrefillTechLog(TechLogHandleChange, TechLogHandleOilChange, selectedAircraft, currentSvc);
            // console.log("In TechLog, AutoClear selectedAircraft->", selectedAircraft);
        // if (selectedAircraft._planeType !== "SIM") {
        //     setOpenButton(true);
        //         console.log("In TechLog, AutoClear openButton->", openButton);
        // } else {
        //     setOpenButton(false);
        // }
    }

    // Handle Clear
    const TechLogHandleClear = async () => {
            // console.log("Calling TechLogHandleClear, selectedAircraft->", selectedAircraft, "aircraftList->", aircraftList);
        
        // setSelectedAircraft(aircraftList[0]);
        // setRegistration(selectedAircraft._registration);
        dispatch({
            type: 'clear',
            numberOfEngines: selectedAircraft.numberOfEngines
        })

        let selection = selectedAircraft._registration
        let currentSvc = 0

        // Update registration to selected plane and prefill departure fuel and svcOff
        if (selection !== "SELECT" && selection !== null) {
            let responseObject = await GetLatestFlight(selection);
                // console.log("In techLog, responseObject->", responseObject);
            if (responseObject.databaseConnection) {
                currentSvc = responseObject.data.svcOn;
                PrefillTechLog(TechLogHandleChange, TechLogHandleOilChange, selectedAircraft, currentSvc);
            } else {
                mostSevereIssue = {
                    status: "error",
                    message: databaseConnectionMessages.error
                };
                UpdateResult(mostSevereIssue);
                UpdateSnackBarStatus(true);
            }
        }
        // PrefillTechLog(TechLogHandleChange, TechLogHandleOilChange, selectedAircraft, currentSvc);
        // if (selectedAircraft._registration !== "SIM") {
        //     setOpenButton(true);
        //         console.log("In TechLog, Clear openButton->", openButton);
        // }
            // console.log("In TechLogHandleClearAuto, submission -> ", submission, "openButton->",openButton)
    }

    const TechLogOpenFlight = (action) => {
            // console.log("In TechLogOpenFlight, action -> ", action);
        dispatch({
            type: action.type,
            flight: action.flight
        })

        // UpdateOpenButton(false);
            // console.log("In TechLog, openFlight openButton->", openButton);
    }

    // Handle Open or Submit
    const TechLogHandleOpenSubmit = async (type) => {

        // Validate the submission object
        // if (type === "flightLog") {
        let validationResultList = Validate(submission, selectedAircraft, type);
        let action = {
            type: "append",
            data: {
                appendixName: "validationResultList",
                appendix: validationResultList,
            }
        }
        dispatch(action);

        // console.log("In TechLog, post-validation, submission->", submission);
        // console.log("In TechLog, post-validation, mostSevereIssue->", mostSevereIssue);
        // console.log("In TechLog, post-validation, validationResultList->", validationResultList);
            
        // Parse the validation results and identify the most severe issue
        if (validationResultList) {
            validationResultList.forEach((result) => {
                if (mostSevereIssue.status !== "error") {
                    mostSevereIssue = _.cloneDeep(result);
                    // mostSevereIssue.status = result.status;
                    // mostSevereIssue.message = result.value
                        // console.log("In TechLog, in result list review, result, mostSevereIssue ->", result, mostSevereIssue);
                }
            })
        }
            // console.log("In TechLog, post-validationList review, mostSevereIssue->", mostSevereIssue);
            // console.log("In TechLog, pre-submission", submission);
        // ***************** If flight has no errors, submit it to the database *****************
        if (mostSevereIssue.status === "success") {  
                // console.log("In TechLog, pre-submission", submission.data);
            // Update the submission object (and so the current render)
            if (type === "departure") {
                submission.data.status.value = "OPEN"    
                submission.data.dateOpened.value = dayjs(new Date()).format("YYYY-MM-DD, HH:mm");
                submission.data.planeType.value = selectedAircraft._planeType;
                dispatch({
                    type: 'open',
                    validationResultList: validationResultList
                }) 
                    // console.log("In techLog, TechLogHandleOpenSubmit post dateOpened update, submission->", submission )
            } else {
                // console.log("In TechLog, pre-submission", submission.data);
                submission.data.status.value = "CLOSED";
                    // console.log("In TechLog, TechLogHandleOpenSubmit, selectedAircraft -> ", selectedAircraft._planeType);
                dispatch({
                    type: 'submit',
                    validationResultList: validationResultList
                })
                submission.data.planeType.value = selectedAircraft._planeType;
                    // console.log("In TechLog, TechLogHandleOpenSubmit, selectedAircraft -> ", selectedAircraft);
                    // console.log("In TechLog, TechLogHandleOpenSubmit, submission -> ", submission);
            } 
            // Call the database submission function and then the feedback trigger with the mostSeverIssue
                // console.log("In TechLog, pre-submitFlight, submission->", submission);
            let responseObject = {};
            submission.data.planeType.value = selectedAircraft._planeType;
                // console.log("In TechLog, TechLogHandleOpenSubmit, submission.data.plateType.value -> ", submission.data.planeType.value);
                // console.log("In TechLog, TechLogHandleOpenSubmit, submission.data.planeType -> ", submission.data.planeType);
                // console.log("In TechLog, submission.data->", submission.data);
            responseObject = await PostFlight(submission.data);
                // console.log("In TechLog, TechLogHandleOpenSubmit, submitFlightResponse->", submitFlightResponse);
                // console.log("In TechLog, TechLogHandleOpenSubmit, submitFlightResponse.status.toString.includes(2)->", submitFlightResponse.status.toString().includes("2"))
            mostSevereIssue = DatabaseConnectionFeedback(responseObject, FeedbackTrigger, mostSevereIssue, GetAircraftData);
            let action = {
                type: "append",
                data: {
                    mostSevereIssue: mostSevereIssue
                }
            }

        } else {            
                // console.log("In TechLog, Open/Submit to FeedbackTrigger, mostSevereIssue->", mostSevereIssue)
            if (type === "departure") {
            // submission.status.value = "OPEN"    
            dispatch({
                type: 'open',
                validationResultList: validationResultList
            }) 
            } else {
                // submission.status.value = "CLOSED"
                dispatch({
                    type: 'submit',
                    validationResultList: validationResultList
                })
            } 

            // Feedback based on most severe issue and update form accordingly
                // console.log("In TechLog, Open/Submit to FeedbackTrigger, mostSevereIssue->", mostSevereIssue)
            FeedbackTrigger(mostSevereIssue)
        }
        //wait 6000 and then GET Aircraft Data
    };

    const handleDeleteClick = (id) => async () => {

        // let rowForUpdate = rows.find((row) => row.id === id);
            // console.log("In handleDeleteClick, id->", id);

        let mostSevereIssue = {
            status: "warning",
            message: flightValidationMessages.warningMessages.delete,
            context: submission.pilot.value,
            action: "delete"
        }
            // console.log("In FlightLogGrid, mostSevereIssue->", mostSevereIssue);
        props.UpdateResult(mostSevereIssue);
        props.UpdateDialogueStatus(true);
    };

    const HandleContinue = async () => {
        // Attempt to write the flight to the database
            // console.log("In TechLog, TechLogHandleContinue")
        let responseObject = {}
        responseObject = await PostFlight(submission.data);
            // console.log("In TechLog, successful submission -> ", submission)
            // console.log("In TechLog, TechLogHandleContinue, responseObject -> ", responseObject);
        mostSevereIssue = DatabaseConnectionFeedback(responseObject, FeedbackTrigger, mostSevereIssue, GetAircraftData);
    };
 
    async function DatabaseConnectionFeedback(responseObject, FeedbackTrigger, mostSevereIssue, GetAircraftData) {
        if (responseObject.databaseConnection) {
            TechLogHandleClearAuto();
            UpdateResult(mostSevereIssue);
            FeedbackTrigger(mostSevereIssue, calledFrom);
            let responseObject = await GetAircraftData();
            setDatabaseConnection(responseObject.databaseConnection)
            setAircraftList(responseObject.data.aircraftList)

        } else {
            mostSevereIssue = {
                status: "error",
                message: databaseConnectionMessages.error
            };
            FeedbackTrigger(mostSevereIssue);
        }
        return mostSevereIssue;
    }

   const FeedbackTrigger = async (mostSevereIssue) => {

    
        // Feedback based on most severe issue and update form accordingly
        if (mostSevereIssue !== undefined) {
                // console.log("In TechLogHandleOpen, post dispatch, submission !== undefined -> ", submission)
            UpdateResult(mostSevereIssue);
                // console.log("In TechLogHandleOpen, result -> ", result);
                // console.log("In TechLogHandleOpen, mostSevereIssue -> ", mostSevereIssue);
            switch(mostSevereIssue.status) {
                case "success":
                    UpdateResult(mostSevereIssue);
                    UpdateSnackBarStatus(true);
                    if (snackBarStatus === false) {
                        TechLogHandleClear();
                        let responseObject = await GetAircraftData();
                            console.log("In TechLog, GetAircraftList, responseObject->", responseObject)
                        setDatabaseConnection(responseObject.databaseConnection)
                        setAircraftList(responseObject.data.aircraftList)
                    }
                break;
    
                case "error":
                        // console.log("In TechLog, In Feedback, submission ->", submission )
                    UpdateResult(mostSevereIssue);
                    UpdateSnackBarStatus(true);
                break;
    
                case "warning":
                    UpdateResult(mostSevereIssue)
                    UpdateDialogueStatus(true);
                break;
    
                default: console.log("Unknown validation result")
            }
        }
    }
    
    // *********************** TECH LOG RENDER ****************************

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            
            <Box>
                <Banner UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                        snackBarStatus = {snackBarStatus} 
                        UpdateResult = {UpdateResult} 
                        TechLogHandleClear = {TechLogHandleClear} 
                        className = "banner" 
                        title = {title} 
                        submission = {submission} 
                        databaseConnection = {databaseConnection} 
                        aircraftList={aircraftList} 
                        selectedAircraft={selectedAircraft} 
                        registration={registration} 
                        UpdateSelection={UpdateSelection}
                        calledFrom = {calledFrom}/>
            </Box>
            <Box  sx= {{width: "100%", zIndex: "5", flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                <OpenFlights selectedAircraft = {selectedAircraft} 
                            TechLogOpenFlight = {TechLogOpenFlight} 
                            TechLogHandleClear = {TechLogHandleClear} 
                            UpdateDatabaseConnection = {UpdateDatabaseConnection}
                            // UpdateOpenButton = {UpdateOpenButton}
                            openButton = {openButton}/> 
            </Box>
            <Box >
                <Departure TechLogHandleChange = {TechLogHandleChange} 
                        TechLogHandleOpenSubmit = {TechLogHandleOpenSubmit} 
                        TechLogHandleClear = {TechLogHandleClear} 
                        openButton = {openButton} 
                        submission = {submission} 
                        aircraftList = {aircraftList} 
                        selectedAircraft = {selectedAircraft} 
                        registration = {registration}/>
            </Box>
            <Box>
                <Arrival TechLogHandleChange = {TechLogHandleChange} 
                        TechLogHandleChangeNumber = {TechLogHandleChangeNumber} 
                        TechLogHandleClear = {TechLogHandleClear} 
                        TechLogHandleOpenSubmit = {TechLogHandleOpenSubmit} 
                        submission = {submission} 
                        selectedAircraft = {selectedAircraft} 
                        registration = {registration}/>
            </Box>
            <Box>
                <AwayLandings TechLogHandleChange = {TechLogHandleChange} submission = {submission} registration={registration}/>
            </Box>
            <Box className = "feedback" sx={{zIndex: "6", position: "sticky", left: "0px", bottom: "80px", margin: "80px"}}>
                <Feedback  HandleContinue = {HandleContinue} 
                        dialogueStatus = {dialogueStatus} 
                        UpdateDialogueStatus = {UpdateDialogueStatus} 
                        snackBarStatus = {snackBarStatus} 
                        UpdateSnackBarStatus = {UpdateSnackBarStatus} 
                        calledFrom = {calledFrom}
                        result = {result}/> 
                        
            </Box>
        </LocalizationProvider>
        
    );
};
export { TechLog };


