//Import libraries
import _ from "lodash"

// Import CSS, images, icons & static data
import { emptyCourseSubmission } from "./CourseData";


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function CreateCourseSubmission(course) {

        // console.log("In CreateCourseSubmission, course->", course)

    let courseSubmission = _.cloneDeep(emptyCourseSubmission); 

        console.log("In CreateCourseSubmission, course->", course)
        
    if (course !== undefined) {
        Object.keys(course).forEach((field)=> {
            if (field === "approvals") {
                course[field].forEach((approval, index) => {
                    if (!courseSubmission.approvals[index]) {
                        courseSubmission.approvals[index] = {
                            id: null,
                            authority:"",
                            approvalDate:"",
                            expiryDate:"",
                        };
                    }
                    courseSubmission.approvals[index].id = approval.id;
                    courseSubmission.approvals[index].authority = approval.authority;
                    courseSubmission.approvals[index].approvalDate = approval.approvalDate;
                    courseSubmission.approvals[index].expiryDate = approval.expiryDate;
                });
            } else {
                if (field === "lessons") {
                    // console.log("In CreateCourseSubmission, course->", course);
                    if (course.lessons.length > 0) {
                        course[field].forEach((lesson, index) => {
                            if (!courseSubmission.lessons[index]) {
                                courseSubmission.lessons[index] = _.cloneDeep(courseSubmission.lessons[0]);
                            }
                            courseSubmission.lessons[index].id = lesson.id;
                            courseSubmission.lessons[index].courseId = course.id;
                            courseSubmission.lessons[index].displayNumber = null
                            courseSubmission.lessons[index].section = lesson.section;
                            courseSubmission.lessons[index].name.value = lesson.name;
                            courseSubmission.lessons[index].objective.value = lesson.objective;
                            courseSubmission.lessons[index].details.value = lesson.details;
                            courseSubmission.lessons[index].minimumHours.value = lesson.minimumHours;
                        });
                    } else {
                        courseSubmission.lessons = [];
                    }
                } else {
                    if (field === "courseImage" || field === "courseIcon" ) {
                        courseSubmission[field] = course[field];
                    } else {
                        courseSubmission[field].value = course[field];
                    }
                }
            }
        })
        courseSubmission.minimumHoursToComplete.value = Number(_.cloneDeep(courseSubmission.minimumHoursToComplete.value))
    }
        // console.log("In CreateCourseSubmission, courseSubmission->", courseSubmission)
    return courseSubmission;
};

