// Import libraries
// import {forwardRef, useState}  from 'react';
import * as React from 'react';
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/base/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function Feedback(props) {

  // console.log("In Feedback, props.dialogueStatus->", props.dialogueStatus);

  // SnackBar Close
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.UpdateSnackBarStatus(false);
    if (props.snackBarStatus === "success") {
      if (props.calledFrom === "TechLog") {
        props.TechLogHandleClear();
        props.GetAircraftData([],[]);
      }
    }
  };

  // Dialogue Close
  const handleCloseDialogue = (e, reason) => {
    if (e.name === "dialogueContinue") {
      props.UpdateDialogueStatus(false);
      props.HandleContinue(props.result);
    } else {
      if (reason === 'clickaway') {
        props.UpdateDialogueStatus(false);
      }
      props.UpdateDialogueStatus(false);
    }
  };
 
  if (props.result) {   
    if (props.result.status !== "" && props.result.status !== undefined) {
      switch (props.result.status) {
        case "":
        case undefined:
            // console.log("In Feedback, switch case (undefined result.status) -> ", props.result.status)
          return;

        case "error": 
        case "success":
        case "info":
            // console.log("In Feedback, result -> ", props.result);
        
          return (
            <React.Fragment>
            <Snackbar open={props.snackBarStatus} autoHideDuration={6000} onClose = {handleClose}>
              <Alert onClose = {handleClose} severity={props.result.status} sx={{zIndex: "6", padding: "4px", margin: "5px", width: "350px" }}>
                {props.result.message}
              </Alert>
            </Snackbar>
            </React.Fragment>
            );
        case "warning":
            // console.log("In Feedback, MADE IT TO HERE - warning!!!");
          // break;
          return (
            <DialogueModel
              handleCloseDialogue = {handleCloseDialogue}
              dialogueStatus = {props.dialogueStatus}
              result = {props.result}
            />
          );
        case "approval":
          // props.result.status = "SELECT AUTHORITY & DATE"
            console.log("In Feedback, props.result->", props.result)
          return (
            <DialogueModel
              handleCloseDialogue = {handleCloseDialogue}
              dialogueStatus = {props.dialogueStatus}
              result = {props.result}
            />
          );
        default: console.log("Validation Status NOT Recognised");
      } // SWITCH END
    } // IF 1 END
  } // IF 2 END
};

function DialogueModel(props) {
  return (
    <React.Fragment>
    <div>
      <Dialog
      open = {props.dialogueStatus}
      onClose = {props.handleCloseDialogue}
      aria-labelledby="Warning"
      // aria-describedby={props.result.message}
      >
      <DialogTitle id="alert-dialog-title" sx={{background: "rgb(255, 248, 220)"}}>
        {props.result.status.toUpperCase()}
        </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx = {{fontWeight: "bold", fontColor: "rgb(0, 200, 255)", padding: "5px"}}>
              {props.result.message}
            </DialogContentText>
          </DialogContent>
        <DialogActions>
          <Button name = "dialogueCancel" variant = "contained" onClick={e => props.handleCloseDialogue(e.target)} autoFocus>Cancel</Button>
          <Button name = "dialogueContinue" variant = "outlined" onClick={e => props.handleCloseDialogue(e.target)}>Continue</Button>
        </DialogActions>
      </Dialog>
    </div>
    </React.Fragment>
  );
}