//Import libraries
import { Box } from "@mui/system";
import { TextField } from "@mui/material"
import React from 'react';
import ArrivalTimesAndDefectsAircraft from "./ArrivalTimesAndDefectsAircraft";
import ArrivalTimesAndDefectsSim from "./ArrivalTimesAndDefectsSim";



// Import CSS, images, icons & static data
import "./Arrival.css"
import shortArrow from "../images/shortArrow.png";


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function ArrivalTimesAndDefects(props) {

    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1) {
            // console.log("In ArrivalTimesAndDefects, props.submission.data.planeType.value->", props.submission.data.planeType.value); 
        return (
            <ArrivalTimesAndDefectsAircraft
                TechLogHandleChange = {props.TechLogHandleChange} 
                TechLogHandleChangeNumber = {props.TechLogHandleChangeNumber} 
                svcDifferenceDisplay = {props.svcDifferenceDisplay}
                blockDifferenceDisplay = {props.blockDifferenceDisplay}
                submission = {props.submission}/>
        );
    } else {
        return (
            <ArrivalTimesAndDefectsSim
                TechLogHandleChange = {props.TechLogHandleChange} 
                TechLogHandleChangeNumber = {props.TechLogHandleChangeNumber} 
                blockDifferenceDisplay = {props.blockDifferenceDisplay}
                submission = {props.submission}/>
        );
    }
}





