// Import libraries
import { TextField, Box } from "@mui/material"
import Media from "../../../utilities/environment/Media";

// Import CSS, images & icons
import "./Arrival.css";


export default function ArrivalTable3Navigation(props) {
    let excludedForAircraftType = ["SIM"];
    if (excludedForAircraftType.indexOf(props.submission.data.planeType.value) === -1) {

        if (Media()) {
        return(
                <div className = "arrival-table3-navigation">
                    <table>
                        <tbody>
                            <tr>
                                <td className = "label" colSpan = "1">Home Landings*</td>
                                <td className = "label" colSpan = "1">Away Landings</td>
                                <td className = "label" colSpan = "1">Night Landings</td>
                            </tr>
                            <tr>
                                <td className = "inputNum" STYLE = {props.submission.data.homeLandings.background}>
                                    <TextField value={props.submission.data.homeLandings.value} 
                                                onChange={e => props.TechLogHandleChangeNumber(e.target)} 
                                                InputProps={{ inputProps: { min: 0, max: 100}}} 
                                                error = {props.submission.data.homeLandings.status === "error"} 
                                                variant = {props.submission.data.homeLandings.variant} fullWidth 
                                                type = "number" 
                                                name = "homeLandings" 
                                                id = "homeLandings" 
                                                required>
                                    </TextField>
                                </td>
                                <td className = "inputNum" STYLE = {props.submission.data.awayLandings.background}>
                                    <TextField value={props.submission.data.awayLandings.value} 
                                                onChange={e => props.TechLogHandleChangeNumber(e.target)} 
                                                InputProps={{ inputProps: { min: 0, max: 100}}} 
                                                error = {props.submission.data.awayLandings.status === "error"} 
                                                variant = {props.submission.data.awayLandings.variant} fullWidth 
                                                type = "number" 
                                                name = "awayLandings" 
                                                id = "awayLandings" 
                                                required>
                                    </TextField>
                                </td>
                                <td className = "inputNum" STYLE = {props.submission.data.nightLandings.background}>
                                    <TextField value={props.submission.data.nightLandings.value} 
                                                onChange={e => props.TechLogHandleChangeNumber(e.target)} 
                                                InputProps={{ inputProps: { min: 0, max: 100}}} 
                                                error = {props.submission.data.nightLandings.status === "error"} 
                                                variant = {props.submission.data.nightLandings.variant} fullWidth 
                                                type = "number" 
                                                name = "nightLandings" 
                                                id = "nightLandings" 
                                                required
                                    ></TextField>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        ); } else {
            return (
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td className = "blank" colSpan = "2"></td>
                            </tr>
                            <tr>
                                <td className = "label" colSpan = "1">Home Landings*</td>
                                <td className = "label" colSpan = "1">Away Landings</td>
                                <td className = "label" colSpan = "1">Night Landings</td>
                            </tr>
                            <tr>
                                <td className = "inputNum" STYLE = {props.submission.data.homeLandings.background}>
                                    <TextField value={props.submission.data.homeLandings.value} 
                                                onChange={e => props.TechLogHandleChange(e.target)} 
                                                InputProps={{ inputProps: { min: 0, max: 100}}} 
                                                error = {props.submission.data.homeLandings.status === "error"} 
                                                variant = {props.submission.data.homeLandings.variant} 
                                                fullWidth 
                                                type = "number" 
                                                name = "homeLandings" 
                                                id = "homeLandings" 
                                                required>
                                    </TextField>
                                </td>
                                <td className = "inputNum" STYLE = {props.submission.data.awayLandings.background}>
                                    <TextField value={props.submission.data.awayLandings.value} 
                                                onChange={e => props.TechLogHandleChangeNumber(e.target)} 
                                                InputProps={{ inputProps: { min: 0, max: 100}}} 
                                                error = {props.submission.data.awayLandings.status === "error"} 
                                                variant = {props.submission.data.awayLandings.variant} fullWidth 
                                                type = "number" 
                                                name = "awayLandings" 
                                                id = "awayLandings" 
                                                required
                                    ></TextField>
                                </td>
                                <td className = "inputNum" STYLE = {props.submission.data.nightLandings.background}>
                                    <TextField value={props.submission.data.nightLandings.value} 
                                                onChange={e => props.TechLogHandleChangeNumber(e.target)} 
                                                InputProps={{ inputProps: { min: 0, max: 100}}} 
                                                error = {props.submission.data.nightLandings.status === "error"} 
                                                variant = {props.submission.data.nightLandings.variant} fullWidth 
                                                type = "number" 
                                                name = "nightLandings" 
                                                id = "nightLandings" 
                                                required
                                    ></TextField>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }   
    }
}