//Import libraries
import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tooltip, useMediaQuery } from '@mui/material';
import { mobileThreshold } from '../utilities/environment/EnvironmentData';
import { HasPermission } from '../utilities/security/HasPermission';

// Import CSS, images, icons & static data




// ******************************* MAIN FUNCTION ************************************************
export default function AdminMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // ***************** SECURITY - CURRENT UER CONTEXT AND ROLE **********************

  var currentUserRoles = props.currentUserRoles
    // console.log("In AdminMenu, currentUserRoles->", currentUserRoles);

  // **************** DATABASE CONNECTION FACILITIES *********************
  // import database function from ./src/components/utilities/database/
  const [databaseConnection, setDatabaseConnection] = useState(false);
  function UpdateDatabaseConnection (response) {
      setDatabaseConnection(response);
  }
  // *********************************************************************

  var marginTop = "22px"
  if (props.currentUser) {
    marginTop = "22px"
  } else {
    marginTop = "7px"
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 
  const handleClickAway = () => {
    setAnchorEl(null);
  }

  const handleClose = (path) => {
    setAnchorEl(null);
    props.handleNavigate(path)
  };

  let media = useMediaQuery(`(min-width:${mobileThreshold}px)`)

  if (media) {
    return (
      <Box>
        <Button
          id="admin"
          onClick={handleClick}
          color = "inherit"
          disabled = {!HasPermission("adminAircraft", currentUserRoles)} 
          sx = {{mt: {marginTop}}}
        >
          Admin
        </Button>
          <Menu
            id="menuMiniAdmin"
            MenuListProps={{'aria-labelledby': 'admin',}}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
            <MenuItem 
              id = "adminAircraft"
              disabled = {!HasPermission("adminAircraft", currentUserRoles)} 
              onClick = {() => handleClose("/adminAircraft")}>Aircraft</MenuItem>
            <MenuItem 
              id = "adminCourse"
              disabled = {!HasPermission("adminCourse", currentUserRoles)} 
              // disabled = {!HasPermission("adminCourse", currentUserRoles)} 
              onClick = {() => handleClose("/adminCourse")}>Courses</MenuItem>
            <MenuItem 
              id = "adminPerson"
              disabled = {!HasPermission("adminPerson", currentUserRoles)} 
              onClick = {() => handleClose("/adminPerson")}>People</MenuItem>
          </Menu>
      </Box>
    );
  } else {
    return (
      <div>
        <Tooltip title = "Admin. Menu">
          <SettingsIcon
            id = "mobileMenuIcon"
            onClick = {handleClick}
            color = "inherit" 
            sx = {{marginTop: '7px', marginLeft: "10px", mb: "0px", verticalAlign: "middle"}}
          >
          </SettingsIcon>
        </Tooltip>
        <Menu
          id="menuMiniMobileAdmin"
          MenuListProps={{'aria-labelledby': 'mobileMenuIcon'}}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
       
            <MenuItem 
              id = "aircraft"
              disabled = {!HasPermission("adminAircraft", currentUserRoles)} 
              onClick = {() => handleClose("/adminAircraft")}>Aircraft</MenuItem>
            <MenuItem 
              id = "courses"
              disabled = {!HasPermission("adminCourse", currentUserRoles)} 
              // disabled = {!HasPermission("adminCourse", currentUserRoles)} 
              onClick = {() => handleClose("/adminCourse")}>Courses</MenuItem>
            <MenuItem 
              id = "adminPerson"
              disabled = {!HasPermission("adminPerson", currentUserRoles)} 
              onClick = {() => handleClose("/adminPerson")}>People</MenuItem>
        </Menu>
      </div>
    );
  }
};