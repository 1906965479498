// Import libraries
import Validate from "../utilities/validation/Validate.js";
import _ from "lodash";
import { DeleteFlight } from "../utilities/database/DeleteFlight.js";
import { PostFlight } from "../utilities/database/PostFlight.js";


// Import CSS, static data, images & icons
import { emptySubmission } from "../flights/flightData/FlightData.js";
import { emptySubmissionSim } from "../flights/flightData/FlightData.js";
import { background } from "../flights/flightData/FlightData.js";
import { flightValidationMessages } from "../utilities/validation/FlightValidationMessages.js";
import { databaseConnectionMessages } from "../utilities/database/DatabaseConnectionMessages.js";


export async function FlightLogFormReducer(action) {
let submission = {};

    // Create your Redux store
  

    
// **************** INTRODUCE CONCEPT OF SUBMISSION LIST FOR MULTIPLE EDITS I PARALLEL *****************


// **************** MANAGE EACH TYPE OF UPDATE ACTION ***********************
    switch(action.type) {

        case "delete":
                // console.log("In FlightLogFormReducer, delete, action->", action);
            let responseObject = {
                databaseConnection: true,
                message: flightValidationMessages.successMessages.delete
            }
            let mostSevereIssue = {}
            if (!action.data.row.id.toString().includes("new")) {
                responseObject = await DeleteFlight(action.data.row);
                    // console.log("In FlightLogFormReducer, responseObject->", responseObject);
                if (responseObject.databaseConnection) {
                    submission.data = {};
                    mostSevereIssue.status = "success";
                    mostSevereIssue.message = responseObject.data;
                    submission.mostSevereIssue = mostSevereIssue;
                    submission.validationResultList = {};
                    submission.responseObject = responseObject;
                        // console.log("In FlightLogFormReducer, submission->", submission);
                } else {
                    submission.data = {};
                    mostSevereIssue.status = "error"
                    mostSevereIssue.message = responseObject.data;
                }
            }
        return submission;

        case "update":
            if (action.data.selectedAircraft.planeType === "SIM") {
                submission = _.cloneDeep(emptySubmissionSim);
            } else {
                submission = _.cloneDeep(emptySubmission);
            }
            submission.action = "update";
                // console.log("In FlightLogFormReducer, submission->", submission);
            Object.keys(action.data.row).forEach((field) => {
                if (submission.data[field] !== undefined) {
                    // console.log("In FlightLogFormReducer, field->", field);
                    submission.data[field].value = action.data.row[field];
                }
            });
                
            // submission.data = _.cloneDeep(action.data.row);
            if (submission.data !== {}) {
                submission.validationResultList = Validate(submission, action.data.selectedAircraft, "update", []);
                //   console.log("In FlightLog, validationResultList->", submission);
            
                submission.validationResultList.forEach((result) => {
                    switch (result.status) {
                        case "error":
                        submission.mostSevereIssue = {
                            status: "error",
                            message: result.message
                        }
                        // TO DO - Update field shading for each related field in the error list
                        break;
                        case "warning":
                            if (submission.mostSevereIssue.status !== "error") {
                                submission.mostSevereIssue = {
                                    status: "warning",
                                    message: result.message
                                }
                            }
                        // TO DO - Update field shading for each related field in the warning list
                        break;
                        case "success":
                            if (submission.mostSevereIssue.status === undefined) {
                                submission.mostSevereIssue = {
                                    status: "success",
                                    message: flightValidationMessages.successMessages.update
                                }
                            }
                        break;
                        default:
                            console.log("In FlightLogFormReducer, invalid validation status.");
                    }
                });
                if (submission.mostSevereIssue.status === undefined) {
                    submission.mostSevereIssue = {
                        status: "success",
                        message: flightValidationMessages.successMessages.update
                    }
                }
                    // console.log("In FlightLogFormReducer, submission.mostSevereIssue.status->", submission.mostSevereIssue.status);
            }
        return submission;

        // Current Focus... 
        case "save":
            submission.data = action.data.row.data;
            submission.action = action.type;
            submission.mostSevereIssue = action.data.row.mostSevereIssue
                // console.log("In FlightLogFormReducer, save, submission->", submission);
            if (submission.mostSevereIssue.status === "success") {
                // console.log("In FlightLogFormReducer, delete, action->", action);
                let responseObject = {
                    databaseConnection: true,
                    message: flightValidationMessages.successMessages.update
                }
                let mostSevereIssue = {}
                if (!submission.data.id.value.toString().includes("new")) {
                        // console.log("In FlightLogFormReducer, save, submission->", submission);
                    responseObject = await PostFlight(submission.data, "update");

                    if (responseObject.databaseConnection) {
                        submission.validationResultList = {};
                        submission.responseObject = responseObject;
                        submission.mostSevereIssue.message = flightValidationMessages.successMessages.update;

                        // Clear all field background colours and field variants
                        Object.keys(submission.data).forEach((field) => {
                            if (submission.data[field].reset) {
                                submission.data[field].status = ""
                                submission.data[field].background = background.normal
                            }
                        })
                        
                    } else {
                        mostSevereIssue.status = "error"
                        mostSevereIssue.message = responseObject.data;
                        submission.mostSevereIssue = mostSevereIssue;
                        submission.databaseConnection = responseObject.databaseConnection;
                            // console.log("FlightLogFormReducer, Save, submission->", submission)
                    }
                }
            }
            
        return submission;
    }

}