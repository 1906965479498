//Import libraries
import { departureMandatoryFields } from "../../flights/flightData/DepartureData.js";
import { flightMandatoryFields } from "../../flights/flightData/FlightData.js";
// import dayjs from 'dayjs';
import moment from "moment";
import {tolerance} from "../../flights/techLog/arrival/FlightTimeSVCAlignment.js";
import RowFieldsResults from "./RowFieldsResults.js";
import { gallonsToLitres } from "../../aircraft/AircraftData.js";
import _ from "lodash";


//Import CSS & images
import { flightValidationMessages } from "./FlightValidationMessages.js";
import { simMandatoryFields } from "../../flights/flightData/ArrivalData.js";

// ********** CHECKING FUNCTIONS **********
// let today = dayjs(new Date()).format("YYYY-MM-DD"); 
// dayjs.extend(utc);
// let today = dayjs(new Date()); 
// console.log("In validations, today initiation ->", today.format("YYYY-MM-DD-HH:MM"));


// ********** CHECK DATES - FUNCTION **********
function ValidateDate(submission, resultList) {
    
    
    let result = {}
    let startOfDay = moment().startOf("day");
    if (startOfDay.diff(moment(submission.data.dateOfFlight.value), "milliseconds") > 0) {
        result = {
            status: "warning",
            message: flightValidationMessages.warningMessages.dateInPast,
            field1: "dateOfFlight",
            field2: "",
            field3: "",
            field4: ""
        }
           
        resultList.push(result);
    }

    let endOfDay = moment().endOf("day");
    if (endOfDay.diff(moment(submission.data.dateOfFlight.value), "milliseconds") < 0) {
        result = _.cloneDeep({});
        result = {
            status: "warning",
            message: flightValidationMessages.warningMessages.dateInFuture,
            field1: "dateOfFlight",
            field2: "",
            field3: "",
            field4: ""
        };
        resultList.push(result);
    }
        // console.log("In FlightValidations, Date Check, result ->", resultList);
    return resultList;
};

// ********** CHECK MANDATORY FIELDS - FUNCTION **********
function ValidateMandatoryFieldsFlight(type, submission, resultList, selectedAircraft) {
    // Construct mandatory fields template
    let result = {};
    let template = _.cloneDeep(departureMandatoryFields);
    if (type === "arrival") {
        template = _.cloneDeep(flightMandatoryFields);
        // ************************** TEMPORARY TO BE REFACTORED *******************************
        if (selectedAircraft._registration === "GFOOJ") {
            template.svcOff = false;
            template.svcOn = false;
        }
         // ************************** TEMPORARY TO BE REFACTORED *******************************
    };
        // console.log("In FlightValidations, selectedAircraft._planeType->", selectedAircraft._planeType)
    if (selectedAircraft._planeType === "SIM") {
        template = simMandatoryFields;
            // console.log("In FlightValidations, template->", template);
            // console.log("In flightValidations, selectedAircraft._plateType->", selectedAircraft._planeType);
            // console.log("In FlightValidations, submission.data->", submission.data);
    }

    // For each required field, check if not empty and, if so, add an item to the result list
        // console.log("In Validations, submission.data) -> ", submission.data);
        // console.log("In Validations, template) -> ", template);
    Object.keys(template).forEach((field) => {
        // console.log("In Validations, field -> )", field);
        // console.log("In Validations, submission.data[field]]->)", submission.data[field]);
        // console.log("In Validations, template[field]->", template[field]);
    if (template[field] && (submission.data[field].value === undefined || 
                            submission.data[field].value === "" || 
                            submission.data[field].value === " " || 
                            submission.data[field].value === null)) {
        result = {
            status: "error",
            message: flightValidationMessages.missingMandatoryField,
            field1: field,
            field2: "",
            field3: "",
            field4: ""
        }
            // console.log("In FlightValidations, field->",field," field.includes(oil)->", field.includes("oil"));
            // console.log("In FlightValidations, selectedAircraft->", selectedAircraft);
            // console.log("In FlightValidations, !(field.includes(oil) && selectedAircraft._planeType === JET)->", !(field.includes("oil") && selectedAircraft._plateType === "JET"));
        if (!(field.includes("oil") && (selectedAircraft._planeType === "JET" || selectedAircraft._planeType === "SIM")))
        {
            resultList.push(result);
            result = _.cloneDeep({});
        }
            // console.log("In validations, validation Result List In Loop -> ", resultList);     
        };
    });
    if (selectedAircraft._numberOfEngines === 2 && type === "arrival" && (selectedAircraft._planeType !== "JET" && selectedAircraft._planeType !== "SIM")) {
        if (submission.data.oilStatusRight.value === undefined || 
            submission.data.oilStatusRight.value === "" || 
            submission.data.oilStatusRight.value === " " || 
            submission.data.oilStatusRight.value === null) {
                result = {
                    status: "error",
                    message: flightValidationMessages.missingMandatoryField,
                    field1: "oilStatusRight",
                    field2: "",
                    field3: "",
                    field4: ""
                }
  
            resultList.push(result);
            result = _.cloneDeep({});
        }
    }
    return resultList;
}

// ********** CHECK AWAY LANDINGS - FUNCTION **********
function ValidateAwayLanding(submission, resultList, type) {

    // Check first row
    let rowFields = {
        location: "awayLandingLocation1",
        quantity: "awayLandingQuantity1",
        details: "awayLandingDetails1",
        paid: "awayLandingPaid1"    
    }
    // If row 1 Quantity = 0 (or the row is from the FlightLog) and any other fields are filled, then mark them as errors
        // console.log("In Validations, awayLanding checks, start, awayLandingQuantity1.value -> ", submission.awayLandingQuantity1.value);
        // console.log("In Validations, awayLanding checks, start, awayLandingQuantity1.value === 0 -> ", submission.data.awayLandingQuantity1.value === 0);
        console.log("In FlightLogValidations, ValidateAwayLandings, type->", type);
    if (submission.data.awayLandingQuantity1.value === 0 || submission.data.awayLandingQuantity1.value === "0" || type === "update") {
            // console.log("In Validations, submission.awayLandingLocation1.value-> ", submission.awayLandingLocation1.value !== "");
            // console.log("In Validations, submission.awayLandingDetails1.value-> ", submission.awayLandingDetails1.value !== "");
            // console.log("In Validations, submission.awayLandingPaid1.value-> ", submission.awayLandingPaid1.value);
        let result = {}
        let row = "row1Zero";
            // console.log("In Validations, awayLanding checks, row1Zero");
        if ((submission.data.awayLandingLocation1.value !== "" && submission.data.awayLandingLocation1.value !== undefined) ||
            (submission.data.awayLandingDetails1.value !== "" && submission.data.awayLandingDetails1.value !== undefined)) {
                    // console.log("In Validations, Quantity === 0 and Loc, Det or paid not false");
                result.status = "error";
                result.message = flightValidationMessages.zeroAwayLandingField;
                resultList = RowFieldsResults(row, result, rowFields, submission, resultList);
                    // console.log("In Validations, validationResultLits -> ", resultList);
        }
    } else {
        // If row 1 Quantity and all the other fields are empty, then mark Quantity as an error
        let result = {}
        let row = "row1Null";
            // console.log("In Validations, awayLanding checks, row1Null, result -> ", result)
            // console.log("In Validations, awayLanding checks, row1Null, submission -> ", submission)
        if ((submission.data.awayLandingQuantity1.value === null || submission.data.awayLandingQuantity1.value === "") && type !== "update") {
                result.status = "error";
                result.message = flightValidationMessages.zeroAwayLandingField;
                resultList = RowFieldsResults(row, result, rowFields, submission, resultList)
                    // console.log("In Validations, awayLanding checks, row1Null, result -> ", result)
            }

        } 

    let awayLandingCount = 1;
    Object.keys(submission.data).forEach((key) => {
            if (key.indexOf("awayLandingLocation") !== -1) {
                awayLandingCount++;
            }
    });     
        // console.log("In Validations, awayLandingCount ->", awayLandingCount);
    let rowCount = 1
        // console.log("In Validations, awayLanding checks, end, awayLandingQuantity1.value -> ", submission.awayLandingQuantity1.value);
    if (submission.data.awayLandingQuantity1.value === null || 
        submission.data.awayLandingQuantity1.value === "" || 
        submission.data.awayLandingQuantity1.value === "0" ||
        submission.data.awayLandingQuantity1.value === 0) {
        rowCount = 2
        };
            // console.log("In Validations, awayLanding checks rowCount -> ", rowCount)
    for (let i = rowCount; i < awayLandingCount; i++) {
            // console.log("In Validations, awayLanding checks i -> ", i)
        let result = {}
        let row = "row1To5";
        // Set result fields
        let rowFields = {
            location: `awayLandingLocation${i}`,
            quantity: `awayLandingQuantity${i}`,
            details: `awayLandingDetails${i}`,
            paid: `awayLandingPaid${i}`
        }
        // Check if any fields in the current row are populated 
            // console.log("In Validations, rowFields.location -> ", rowFields.location, "submission -> ", submission )
        // if (submission) {
        if (submission.data[rowFields.location].value !== "" ||
            (submission.data[rowFields.quantity].value !== null && submission.data[rowFields.quantity].value !== "") ||
            submission.data[rowFields.details].value !== "" || 
            submission.data[rowFields.paid].value) {
            // If any fields in the current row are populated AND one is NOT populated mark as an error
            if (submission.data[rowFields.location].value === "" || 
                (submission.data[rowFields.quantity].value === null || submission.data[rowFields.quantity].value === "" ) ||
                submission.data[rowFields.details].value === "") {
                    result.status = "error";
                    result.message = flightValidationMessages.missingMandatoryField;
                    resultList = RowFieldsResults(row, result, rowFields, submission, resultList)
            }
        }
            // console.log("In validations, away landings checks, resultList -> ", resultList)
    }
    return resultList;
}; 
        
// ********** CHECK TIMES - FUNCTION **********

function ValidateSimTimes(submission, resultList) {
    // console.log("In validations, Start Time validation, submission ->", submission);
    let result = {};
    // Is off block time after on block time
        // console.log("In validations, Times, OffBlocks < OnBlocks -> ", submission.offBlocks, submission.onBlocks)

    if (Number(submission.data.flightTime.value) < 0) {
        result = {
            status: "error",
            message: flightValidationMessages.timeErrorMessages.flightTime,
            field1: "takeOff",
            field2: "landing",
            field3: "",
            field4: ""
        }
        resultList.push(result);
        // console.log("In validations, post flightTime check, resultList->",resultList);
    }
    return resultList;
}

//Is TakeOff after Landing
function ValidateTimes(submission, resultList) {
        // console.log("In validations, Start Time validation, submission ->", submission);
    let result = {};
    let isTheFOOJ = (submission.data.registration.value === "GFOOJ");

    // Is off block time after on block time
        // console.log("In validations, Times, OffBlocks < OnBlocks -> ", submission.offBlocks, submission.onBlocks)
    if (Number(submission.data.blockDifference.value) < 0) {
        result = {
            status: "error",
            message: flightValidationMessages.timeErrorMessages.blockDifference,
            field1: "offBlocks",
            field2: "onBlocks",
            field3: "",
            field4: ""
        }
    resultList.push(result);
        // console.log("In validations, post block-diff checks, resultList->",resultList);
    }

    // Is take off time after landing time
        // console.log("In validations, Times, submission.flightTime.value -> ", submission.flightTime.value)
    if (Number(submission.data.flightTime.value) < 0) {
        result = {
            status: "error",
            message: flightValidationMessages.timeErrorMessages.flightTime,
            field1: "takeOff",
            field2: "landing",
            field3: "",
            field4: ""
        }
    resultList.push(result);
        // console.log("In validations, post flightTime check, resultList->",resultList);
    }

    //Is SVC Off greater than SVC On
        // console.log("In validations, Times, submission.svcDifference.value -> ", submission.svcDifference.value)
    if (!isTheFOOJ) {
        if (Number(submission.data.svcDifference.value) < 0) {
            result = {
                status: "error",
                message: flightValidationMessages.timeErrorMessages.svcTime,
                field1: "svcOff",
                field2: "svcOn",
                field3: "",
                field4: ""
            }
        resultList.push(result);
            // console.log("In validations, post SVC checks, resultList->",resultList);
        }
    }

    // Is take off before off block time
        // console.log("In validations, Times, submission.offBlocks -> ", submission.offBlocks);
    if (submission.data.offBlocks.value > submission.data.takeOff.value) {
        result = {
            status: "error",
            message: flightValidationMessages.timeErrorMessages.offBlockAfterTakeOff,
            field1: "offBlocks",
            field2: "takeOff",
            field3: "",
            field4: ""
        }
    resultList.push(result);
        // console.log("In validations, resultList ->",resultList);
    }

    // Is landing before after onblock time
        // console.log("In Validations, Landing and onBlocks check -> ", submission.landing.value, submission.onBlocks.value)
    if (submission.data.landing.value > submission.data.onBlocks.value) {
        result = {
            status: "error",
            message: flightValidationMessages.timeErrorMessages.landingTimeAfterOnBlockTIme,
            field1: "landing",
            field2: "onBlocks",
            field3: "", 
            field4: ""
        }
    resultList.push(result);
        // console.log("In validations, resultList ->",resultList);
    }

    //Is Flight time more than + or - 5mins different to SVC time
        // console.log(tolerance);
    if (!isTheFOOJ) {
        if (Math.abs(Number(submission.data.flightTime.value) - Number(submission.data.svcDifference.value)) > tolerance) {
            result = {
                status: "error",
                message: flightValidationMessages.timeErrorMessages.svcFlightTimeAlignment,
                field1: "takeOff",
                field2: "landing",
                field3: "svcOff",
                field4: "svcOn"
            }
        resultList.push(result);
            // console.log("In validations, post flightTime check, resultList->",resultList);
        }
    }
    return resultList;
};

// ********************************* CHECK FUEL DEPARTURE ***************************************
function ValidateDepartureFuel (submission, resultList, selectedAircraft) {
    let result = {}
    
    //************************** CHECK DEPARTURE UPLIFT IS LESS THAN DEPARTURE STATUS ******************************** */
        // console.log("In FlightValidations, submission.departureFuelLeft.value->", Number(submission.departureFuelLeft.value),"submission.departureFuelUpliftLeft.value->", Number(submission.departureFuelUpliftLeft.value))
    if (Number(submission.data.departureFuelLeft.value) < Number(submission.data.departureFuelUpliftLeft.value /  gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.departureStatus,
            field1: "departureFuelUpliftLeft",
            field2: "departureFuelLeft",
            field3: "",
            field4: ""
        }
        resultList.push(result);
        result = _.cloneDeep({});
            // console.log("In validations, Post Fuel Left resultList ->",resultList);
    }
        
    if (Number(submission.data.departureFuelRight.value) < Number(submission.data.departureFuelUpliftRight.value / gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.departureStatus,
            field1: "departureFuelUpliftRight",
            field2: "departureFuelRight",
            field3: "",
            field4: ""
        }
        resultList.push(result);
        result = _.cloneDeep({});
        // console.log("In validations, Post Fuel Right resultList ->",resultList);
    }


    //************************** CHECK DEPARTURE FUEL LEVELS ARE LESS THAN TANK CAPACITY ******************************** */
        // console.log("In FlightValidations, submission.departureFuelLeft.value->", Number(submission.departureFuelLeft.value),"selectedAircraft.fuelTank1Capacity->", selectedAircraft.fuelTank1Capacity)
    if (Number(submission.data.departureFuelLeft.value) > Number(selectedAircraft.fuelTank1Capacity)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "departureFuelLeft",
            field2: "",
            field3: "",
            field4: ""
        }

        resultList.push(result); 
        result = _.cloneDeep({});
    }
        // console.log("In validations, Post departure fuel Left resultList ->",resultList);

    if (Number(submission.data.departureFuelRight.value) > Number(selectedAircraft.fuelTank2Capacity)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "departureFuelRight",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result);  
        result = _.cloneDeep({});  
    }
        // console.log("In validations, Post departure fuel right resultList ->",resultList);

    if (Number(submission.data.departureFuelUpliftLeft.value) > Number(selectedAircraft.fuelTank1Capacity * gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "departureFuelUpliftLeft",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result); 
        result = _.cloneDeep({});
    }

    if (Number(submission.data.departureFuelUpliftRight.value) > Number(selectedAircraft.fuelTank2Capacity * gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "departureFuelUpliftRight",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result); 
        result = _.cloneDeep({});
    }
        // console.log("In FlightValidations, Post Departure Fuel Checks, resultList->", resultList)
    return resultList;
}

// ********************************* CHECK FUEL ARRIVAL *****************************************
function ValidateArrivalFuel(submission, resultList, selectedAircraft) {
    // console.log("In validations, Fuel, submission.arrivalFuelLeft, submission.arrivalFuelUpliftLeft -> ", submission.arrivalFuelLeft.value, submission.arrivalFuelUpliftLeft.value)
    let result = {};
    if (Number(submission.data.arrivalFuelLeft.value) < Number(submission.data.arrivalFuelUpliftLeft.value / gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.arrivalStatus,
            field1: "arrivalFuelUpliftLeft",
            field2: "arrivalFuelLeft",
            field3: "",
            field4: ""
        }
        resultList.push(result);
        result = _.cloneDeep({});
            // console.log("In validations, Post Arrival Fuel Left resultList ->",resultList);
    }
        
    if (Number(submission.data.arrivalFuelRight.value) < Number(submission.data.arrivalFuelUpliftRight.value / gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.arrivalStatus,
            field1: "arrivalFuelUpliftRight",
            field2: "arrivalFuelRight",
            field3: "",
            field4: ""
        }
        resultList.push(result);
        result = _.cloneDeep({});
        // console.log("In validations, Post Fuel Right resultList ->",resultList);
    }

    //************************** CHECK ARRIVAL FUEL LEVELS VS TANK CAPACITY ******************************** */
    if (Number(submission.data.arrivalFuelLeft.value) > Number(selectedAircraft.fuelTank1Capacity)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "arrivalFuelLeft",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result); 
        result = _.cloneDeep({});
    }

        // console.log("In FlightValidations, arrivalFuel, submission.data.arrivalFuelRight.value->", submission.data.arrivalFuelRight.value);
        // console.log("In FlightValidations, arrivalFuel, selectedAircraft.fuelTank2Capacity->", selectedAircraft.fuelTank2Capacity);
    if (Number(submission.data.arrivalFuelRight.value) > Number(selectedAircraft.fuelTank2Capacity)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "arrivalFuelRight",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result);    
        result = _.cloneDeep({});
    }

    if (Number(submission.data.arrivalFuelUpliftLeft.value) > Number(selectedAircraft.fuelTank1Capacity * gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "arrivalFuelUpliftLeft",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result); 
        result = _.cloneDeep({});
    }

    if (Number(submission.data.arrivalFuelUpliftRight.value) > Number(selectedAircraft.fuelTank2Capacity * gallonsToLitres)) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.overCapacity,
            field1: "arrivalFuelUpliftRight",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result); 
        result = _.cloneDeep({});
    }
    
        // console.log("In FlightValidations, Number(submission.data.fuelUsed)->", submission.data.fuelUsed.value);
    if (Number(submission.data.fuelUsed.value) < 0) {
        result = {
            status: "error",
            message: flightValidationMessages.fuelErrorMessages.negativeFuelUsed,
            field1: "fuelUsed",
            field2: "",
            field3: "",
            field4: ""
        }
        resultList.push(result); 
        result = _.cloneDeep({});
    }



    return resultList;
};

// ******************************* MAIN FUNCTION ************************************************
export default function FlightValidations(submission, selectedAircraft, type) {
        // console.log("In Validations, START, submission -> ", submission);
        console.log("In Validations, START, selectedAircraft -> ", selectedAircraft);
        // console.log("In Validations, START, type -> ", type);

    // Initialise status and message
    let result = {
        status: "success",
        message: flightValidationMessages.successMessages.departure,
        field1: "",
        field2: "",
        field3: "",
        field4: ""
    };

  
        // console.log("In Validations, result Departure Success -> ", result)
    if (type === "arrival") {
        result.message = flightValidationMessages.successMessages.arrival
            // console.log("In Validations, result Arrival Success -> ", result)
    }

    if (type === "update") {
        result.message = flightValidationMessages.successMessages.update
            // console.log("In Validations, result Update Success -> ", result)
    }
   
    let list = [];
    let resultList = [];
    // resultList.push({});
    list.push(result);
        // console.log("In validations, initialised resultList->", resultList);
        // console.log("In validations, initialised list->", list);
     


    // ************* RUN CHECKS *************
    // CHECK -> Aircraft selection check
    if (submission.data.registration.value === "SELECT" || submission.data.registration.value === "" ) {
        result.status = "error";
        result.message = flightValidationMessages.planeSelectionMessage;
        result.field1 = "registration";
        resultList.push(result);
    } else {

        // CHECK -> Date checks
        if (type !== "update") {
            resultList = ValidateDate(submission, resultList);
                // console.log("In Validations, Post-Date Check, resultList -> ", resultList);
        }

        // CHECK -> Mandatory field checks
        resultList = ValidateMandatoryFieldsFlight(type, submission, resultList, selectedAircraft);
        if (selectedAircraft._planeType !== "SIM") { 
            resultList = ValidateDepartureFuel(submission, resultList, selectedAircraft)
        }

        if (selectedAircraft._planeType === "SIM") { 
            resultList = ValidateSimTimes(submission, resultList);
        }
        
        if ((type === "arrival" || type === "update") && selectedAircraft._planeType !== "SIM") { 
            resultList = ValidateTimes(submission, resultList);
            resultList = ValidateArrivalFuel(submission, resultList, selectedAircraft); 
        }   

        if ((type === "arrival" || type === "update") && selectedAircraft._planeType !== "SIM") {
            resultList = ValidateAwayLanding(submission, resultList, type);
        }
    }
        console.log("In validations, post all checks performed resultList->", resultList);
    if (resultList.length === 0 || (resultList.length === 1 && (resultList[0].status === undefined || resultList[0].status === ""))) {
        result = {
            status: "success",
            message: flightValidationMessages.successMessages.departure,
            field1: "",
            field2: "",
            field3: "",
            field4: ""
        };
            // console.log("In Validations, result Departure Success-> ", result)
            // console.log("In Validations, type ->", type)
        if (type === "arrival") {
            result.message = flightValidationMessages.successMessages.arrival
                // console.log("In Validations, result Arrival Success-> ", result)
        }   
        if (type === "update") {
            result.message = flightValidationMessages.successMessages.update
                // console.log("In Validations, result Arrival Success Post -> ", result)
        }   
        resultList.push(result)
            // console.log("In Validations, Post-ALL Checks, validationsResultList -> ", resultList);
        // resultList = resultList.toReversed();
            // console.log("In Validations, Post-Reverse, validationsResultList -> ", resultList);
        // resultList.pop();

    }
        // console.log("In Validations, At Return, resultList ->", resultList)
    return resultList;
};