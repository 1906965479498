// Import libraries
// import axios from "axios";
import { API_URL } from "../../../config/config";
import axiosInstance from "../security/AxiosConfig";


// Import CSS, images, icons & static data
import { defaultFlightList } from "../../flights/flightData/FlightData";

// Import fonts



//*********************************** MAIN FUNCTION ************************************** */
export async function GetFlightListPilot(selectedPilot) {
      console.log("In GetFlightListPilot, selectedPilot->", selectedPilot)
    let responseObject = {}
    try {
      let response = await axiosInstance.get(`${API_URL}/flights/pilotLogFlights?pilot=${selectedPilot}`);
      responseObject.data = response.data;
      responseObject.databaseConnection = true;
    } catch (error) {
        console.error('Error fetching flights', error);
        responseObject.databaseConnection = false
        responseObject.data = []
    }
    return(responseObject)
  }