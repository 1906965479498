// Import libraries
import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import {Box} from "@mui/material"
import {useMediaQuery} from '@mui/material';
import { FixedSizeList } from 'react-window';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Checkbox} from '@mui/material';
import { mobileThreshold } from '../../utilities/environment/EnvironmentData';

// Import CSS & images

// function AircraftHandleChange(e) {
//     console.log("In CreateDefectDisplayList, AircraftHandleChange, e->", e)
// }


// ******************************* MAIN FUNCTION ************************************************
export default function CreateDefectsDisplayList(props) {

        // console.log("In CreateListItems, props.aircraftSubmission.defects.length-> ", props.aircraftSubmission.defects.length)
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
    const [selectedAircraft, setSelectedAircraft] = useState(props.selectedAircraft);
    const [defectListLength, setDefectListLength] = useState(props.aircraftSubmission.defects.length)

    function RenderRow(defect) {
        // const [checked, setChecked] = useRef(defect.status)
        // let checked = defect.defectStatus
        let id = defect.id.toString();
            // console.log("In CreateDefectList, id->", id)
            // console.log("In CreateDefectList, defect->", defect)
            // console.log("In CreateDefectList, defect.defectStatus->", defect.defectStatus)
            // console.log("In CreateDefectList, defect.defectStatus === CLOSED->", (defect.defectStatus === "CLOSED"))
        return (
            <ListItem key = {defect.id} component = "div" >
                <ListItemText primary = {defect.details} secondary = {defect.dateRaised}/>
                <Checkbox
                    // checked = {defect.status === "CLOSED"}
                    checked = {defect.defectStatus === "CLOSED"} 
                    value = {defect.defectStatus === "CLOSED"}
                    status = {defect.defectStatus}
                    name = "defectCheckbox" id = {id}
                    disabled = {props.editing === false}
                    onChange = {e => props.AircraftHandleChange(e.target)}>
                </Checkbox>
            </ListItem>
        );
    }

    return (
        <Box>
            <List
                height={400}
                width={"auto"}
                // itemSize={46}
                // itemCount={defectListLength}
                // overscanCount={5}
            >
                {props.aircraftSubmission.defects.map((defect) => {
                    return RenderRow(defect)
                })
                }
            </List>
        </Box>
    )

}