//Import libraries
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import AdminCourseMain from "../admin/course/AdminCourseMain";
import {useMediaQuery} from '@mui/material';
import { useAuthFirebase } from '../utilities/security/AuthContextFirebase';
import { HasPermission }  from '../utilities/security/HasPermission';
import _ from "lodash";


// Import CSS, images, icons & static data
import "./CourseSelection.css"
import { mobileThreshold } from '../utilities/environment/EnvironmentData.js';


// Import fonts


//**************************** MAIN FUNCTION ***************************** */
export default function CourseTile(props) {
        // console.log("In CourseTiles, Object.keys(props.course.image)->", Object.keys(props.course.icon))

    let iconURL = Object.values(props.courseStatic.icon)[0];


    const handleClick = (path) => {
        props.handleNavigate(path)
    }

        // console.log("In CourseTile, props.course->", props.course); 

    let courseTileClass = "";
    let courseIconClass = "";  
    let tileDisabled = true;

    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`)
        // console.log("In CourseTile, props.courseStatic->", props.courseStatic);


    if (media) {
        courseTileClass = "courses-tile" 
        courseIconClass = "courses-icon" 
    } else {
        courseTileClass = "courses-tile-mobile" 
        courseIconClass = "courses-icon-mobile" 
    }

    if (props.courseStatic.id) {
        tileDisabled = false;

    }

    return (
        <Button 
            className = "courses-button" 
            name = {props.courseStatic.name} 
            id = {props.courseStatic.name} 
            disabled = {tileDisabled === true}
            onClick = {() => handleClick(`CourseMain/:${props.course.id.value}`)}>
            <Box className = {courseTileClass}>
                <Box sx = {{mt: "10px", ml: "0px"}}>
                    {props.courseStatic.name}
                </Box>
                <Box>
                    <img className = {courseIconClass} src = {`${iconURL}`}/>
                </Box>
            </Box>
        </Button>
    );
};