// Import libraries
import React, {useState, useEffect} from "react";
import { TextField, Box } from "@mui/material"
import {FlightTimeSVCAlignment} from "./FlightTimeSVCAlignment.js"
import Media from "../../../utilities/environment/Media.js";
import FlightTimeSlider from "./FlightTimeSlider.js";

// Import CSS, images & icons
import map from "../images/map.png";
import clock from "../images/clock.png";
import shortArrow from "../images/shortArrow.png";
import ArrivalTableDefects from "./ArrivalTableDefects.js";
import longArrow from "../images/longArrow.png";



import "./Arrival.css";


export default function ArrivalTimesAndDefectsAircraft(props) {

    let isTheFOOJ = props.submission.data.registration.value === "GFOOJ"
    let labelOption = "label"
    let calcOption = "calc"

    // console.log("In ArrivalTimesAndDefectsAircraft, isTheFooJ->", isTheFOOJ)
    // console.log("In ArrivalTimesAndDefectsAircraft, props.submission->", props.submission)

    if (isTheFOOJ) {
        labelOption = "labelLight"
        calcOption = "labelLight"
    } else {
        labelOption = "label"
        calcOption = "calc"
    }


    if (Media()) {
        // flightTimeRowSpan = "4"
        // Mobile Render
        return (
            <Box>
                <Box className = "arrival-table-times">
                    <table >
                        <tbody>
                            <tr>
                                <td colSpan = "3"><img className = "clock" src = {clock} alt = "Clock"></img></td>
                            </tr>
                            <tr>
                                <td className = "label" rowSpan = "6">Flight Times<br></br><span className = "footnote">(00:00z)</span></td>
                                <td className = "labelLight">Off Blocks*</td>
                                <td className = "labelLight">On Blocks*</td>
                                <td className = "blank"></td>   
                                <td className = "label">Block Difference</td>
                            </tr>
                            <tr>
                                <td className = "input" STYLE = {props.submission.data.offBlocks.background}>
                                    <TextField 
                                        type = "time" timezone = "UTC" name = "offBlocks" id = "offBlocks"
                                        value={props.submission.data.offBlocks.value} 
                                        variant = {props.submission.data.offBlocks.variant}
                                        error = {props.submission.data.offBlocks.status === "error"}
                                        fullWidth 
                                        disabled = {true} 
                                        onChange={e => props.TechLogHandleChange(e.target)}>
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.onBlocks.background}>
                                    <TextField 
                                        type = "time" timezone = "UTC" name = "onBlocks" id = "onBlocks"
                                        value={props.submission.data.onBlocks.value} 
                                        variant = {props.submission.data.onBlocks.variant} 
                                        error = {props.submission.data.onBlocks.status === "error"}
                                        fullWidth 
                                        disabled = {true} 
                                        onChange={e => props.TechLogHandleChange(e.target)}>
                                    </TextField>
                                </td>
                                <td><img src = {shortArrow} className = "shortArrow" alt = "Arrow"></img></td>
                                <td className = "calc" name="blockDifference" id="blockDifference">{props.blockDifferenceDisplay}</td>
                            </tr>       
                            <tr>
                                <td className = "label">Take Off*</td>
                                <td className = "label">Landing*</td>
                                <td rowSpan = "2" colSpan = "2">
                                    <FlightTimeSVCAlignment 
                                        submission = {props.submission} 
                                        flightTime = {props.submission.data.flightTime.value} 
                                        svcDifference = {props.submission.data.svcDifference.value}>
                                    </FlightTimeSVCAlignment>
                                </td>
                            </tr>
                            <tr>
                                <td className = "input" STYLE = {props.submission.data.takeOff.background}>
                                    <TextField 
                                        type = "time" timezone = "UTC" name = "takeOff" id = "takeOff"
                                        value={props.submission.data.takeOff.value} 
                                        variant = {props.submission.data.takeOff.variant} 
                                        error = {props.submission.data.takeOff.status === "error"} 
                                        fullWidth 
                                        onChange={e => props.TechLogHandleChange(e.target)}>
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.landing.background}>
                                    <TextField 
                                        type = "time" timezone = "UTC" name = "landing" id = "landing"
                                        value={props.submission.data.landing.value} 
                                        variant = {props.submission.data.landing.variant} 
                                        error = {props.submission.data.landing.status === "error"} 
                                        fullWidth 
                                        onChange={e => props.TechLogHandleChange(e.target)}>
                                    </TextField>
                                </td>
                                {/* <td rowSpan = "2" colSpan = "2"><FlightTimeSVCAlignment submission = {props.submission} flightTime = {props.submission.flightTime.value} svcDifference = {props.submission.svcDifference.value}/></td> */}
                            </tr>
                            <tr rowSpan = "1" className = "blank">
                                {/* <td className = "blank" colSpan = "1"></td> */}
                                <td className = {labelOption}>SVC Off*</td>
                                <td className = {labelOption}>SVC On*</td>
                                <td className = "blank" colSpan = "1"></td>
                                <td className = "label">SVC Difference</td>
                            </tr>
                            <tr>
                                {/* <td className = "blank" colSpan = "1"></td> */}
                                <td className = "input" STYLE = {props.submission.data.svcOff.background}>
                                    <TextField 
                                        type = "number" name = "svcOff" id ="svcOff" 
                                        value={props.submission.data.svcOff.value} 
                                        variant = {props.submission.data.svcOff.variant}
                                        error = {props.submission.data.svcOff.status === "error"}
                                        fullWidth 
                                        disabled = {isTheFOOJ === true}
                                        onChange={e => props.TechLogHandleChange(e.target)}
                                        InputProps={{ inputProps: { min: 0 }}}>
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.svcOn.background}>
                                    <TextField 
                                        type = "number" name = "svcOn" id ="svcOn"
                                        value={props.submission.data.svcOn.value} 
                                        variant = {props.submission.data.svcOn.variant} 
                                        error = {props.submission.data.svcOn.status === "error"}
                                        fullWidth 
                                        disabled = {isTheFOOJ === true}
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        InputProps={{ inputProps: { min: 0 }}}> 
                                    </TextField>
                                </td>
                                {/* <td className = "blank" colSpan = "1"></td> */}
                                <td><img src = {shortArrow} className = "shortArrow" alt = "Arrow"></img></td>
                                <td className = "calc" name="svcDifference" id="svcDifference">{props.svcDifferenceDisplay}</td>  
                            </tr>
                            <tr>    
                                <td className="blank" colSpan="1"></td>
                                <td className="blank" colSpan="2">
                                    <FlightTimeSlider 
                                        submission = {props.submission}
                                        blockDifferenceDisplay = {props.blockDifferenceDisplay} 
                                        TechLogHandleChange = {props.TechLogHandleChange} 
                                        type = "instrumentTime"/>
                                    <FlightTimeSlider 
                                        submission = {props.submission}
                                        blockDifferenceDisplay = {props.blockDifferenceDisplay} 
                                        TechLogHandleChange = {props.TechLogHandleChange}
                                        type = "nightTime"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
                <Box>
                    <ArrivalTableDefects
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        // blockDifferenceDisplay = {blockDifferenceDisplay} 
                        // svcDifferenceDisplay = {svcDifferenceDisplay} 
                        submission = {props.submission}/>
                </Box>
            </Box>
        );
    } else {
        // Desktop Render
        return (
            <Box className = "arr-details-row3">
                <Box className = "arrival-table-times-wide">
                    <table >
                        <tbody>
                            <tr>
                                <td colSpan = "1"><img className = "clock" src = {clock} alt = "Clock"></img></td>
                                <td rowSpan = "1" colSpan = "4">
                                    <FlightTimeSVCAlignment 
                                        submission = {props.submission} 
                                        flightTime = {props.submission.data.flightTime.value} 
                                        svcDifference = {props.submission.data.svcDifference.value}/>
                                </td>
                                {/* <td><img className = "longArrow" colSpan = "4" src = {longArrow} alt = "Arrow"/></td> */}
                            </tr>
                            <tr>
                                <td className = "label" rowSpan = "4">Flight Time*<br></br><span className = "footnote">(00:00z)</span></td>
                                <td className = "labelLight">Off Blocks*</td>
                                <td className = "label">Take Off*</td>
                                <td className = "label">Landing*</td>
                                <td className = "labelLight">On Blocks*</td>
                                <td className = "blank"></td>
                                <td className = "label">Block Difference</td>
                            </tr>
                            <tr>
                                <td className = "input" STYLE = {props.submission.data.offBlocks.background}>
                                    <TextField 
                                        value={props.submission.data.offBlocks.value} 
                                        disabled = {true} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.offBlocks.status === "error"} 
                                        variant = {props.submission.data.offBlocks.variant} 
                                        fullWidth 
                                        type = "time" 
                                        name = "offBlocks" 
                                        id = "offBlocks">
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.takeOff.background}>
                                    <TextField 
                                        value={props.submission.data.takeOff.value} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.takeOff.status === "error"} 
                                        variant = {props.submission.data.takeOff.variant}
                                        fullWidth 
                                        type = "time" 
                                        name = "takeOff" 
                                        id = "takeOff">
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.landing.background}>
                                    <TextField 
                                        value={props.submission.data.landing.value} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.landing.status === "error"} 
                                        variant = {props.submission.data.landing.variant} 
                                        fullWidth 
                                        type = "time" 
                                        name = "landing" 
                                        id = "landing">
                                    </TextField>
                                </td>    
                                <td className = "input" STYLE = {props.submission.data.onBlocks.background}>
                                    <TextField value={props.submission.data.onBlocks.value} 
                                        disabled = {true} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.onBlocks.status === "error"} 
                                        variant = {props.submission.data.onBlocks.variant} 
                                        fullWidth 
                                        type = "time" 
                                        name = "onBlocks" 
                                        id = "onBlocks">
                                    </TextField>
                                </td>
                                <td><img src = {shortArrow} className = "shortArrow" alt = "Arrow"></img></td>
                                <td className = "calc" name="blockDifference" id="blockDifference">{props.blockDifferenceDisplay}</td>
                            </tr>
                            <tr rowSpan = "1" className = "blank">
                                <td className = "blank" colSpan = "1"></td>
                                <td className = {labelOption}>SVC Off*</td>
                                <td className = {labelOption}>SVC On*</td>
                                <td className = "blank" colSpan = "2"></td>
                                <td className = "label">SVC Difference</td>
                            </tr>
                            <tr>
                                <td className = "blank" colSpan = "1"></td>
                                <td className = "input" STYLE = {props.submission.data.svcOff.background}>
                                    <TextField 
                                        type = "number" name = "svcOff" id ="svcOff" 
                                        value={props.submission.data.svcOff.value} 
                                        variant = {props.submission.data.svcOff.variant}
                                        error = {props.submission.data.svcOff.status === "error"}
                                        fullWidth
                                        disabled = {isTheFOOJ === true}
                                        onChange={e => props.TechLogHandleChange(e.target)}
                                        InputProps={{ inputProps: { min: 0 }}}>
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.svcOn.background}>
                                    <TextField 
                                        type = "number" name = "svcOn" id ="svcOn"
                                        value={props.submission.data.svcOn.value} 
                                        variant = {props.submission.data.svcOn.variant} 
                                        error = {props.submission.data.svcOn.status === "error"}
                                        fullWidth
                                        disabled = {isTheFOOJ === true}
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        InputProps={{ inputProps: { min: 0 }}}> 
                                    </TextField>
                                </td>
                                <td className = "blank" colSpan = "1"></td>
                                <td><img src = {shortArrow} className = "shortArrow" alt = "Arrow"></img></td>
                                <td className = "calc" name="svcDifference" id="svcDifference">{props.svcDifferenceDisplay}</td>  
                            </tr>
                            <tr>    
                                <td className="blank" colSpan="2"></td>
                                <td className="blank" colSpan="2">
                                    <FlightTimeSlider 
                                        blockDifferenceDisplay = {props.blockDifferenceDisplay} 
                                        TechLogHandleChange = {props.TechLogHandleChange} 
                                        submission = {props.submission}
                                        type = {"instrumentTime"}/>
                                    <FlightTimeSlider 
                                        blockDifferenceDisplay = {props.blockDifferenceDisplay} 
                                        TechLogHandleChange = {props.TechLogHandleChange}
                                        submission = {props.submission}
                                        type = {"nightTime"}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
                <Box>
                    <ArrivalTableDefects
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        // blockDifferenceDisplay = {blockDifferenceDisplay} 
                        // svcDifferenceDisplay = {svcDifferenceDisplay} 
                        submission = {props.submission}/>
                </Box>
            </Box>
        )
    }
}
