// Import libraries
import React, {useState, useEffect} from "react";
import { TextField, Box } from "@mui/material"
import {FlightTimeSVCAlignment} from "./FlightTimeSVCAlignment.js"
import ArrivalTable2Navigation from "./ArrivalTable2Navigation.js";
import Media from "../../../utilities/environment/Media.js";
import FlightTimeSlider from "./FlightTimeSlider.js";

// Import CSS, images & icons
import map from "../images/map.png";
import clock from "../images/clock.png";
import shortArrow from "../images/shortArrow.png";
import ArrivalTableDefects from "./ArrivalTableDefects.js";
import longArrow from "../images/longArrow.png";



import "./Arrival.css";


export default function ArrivalTimesAndDefectsAircraft(props) {
        return (
            <Box className = "arr-details-row3">
                <Box className = "arrival-table-times-wide">
                    <table >
                        <tbody>
                            <tr>
                                <td colSpan = "1"><img className = "clock" src = {clock} alt = "Clock"></img></td>
                                <td rowSpan = "1" colSpan = "4">
                                    <FlightTimeSVCAlignment 
                                        submission = {props.submission} 
                                        flightTime = {props.submission.data.flightTime.value} 
                                        svcDifference = {props.submission.data.svcDifference.value}/>
                                </td>
                            </tr>
                            <tr>
                                <td className = "label" rowSpan = "2">Flight Time*<br></br><span className = "footnote">(00:00z)</span></td>
                                <td className = "label">Start Time*</td>
                                <td className = "label">End Time*</td>
                                <td className = "blank"></td>
                                <td className = "label">Total Time</td>
                            </tr>
                            <tr>
                                <td className = "input" STYLE = {props.submission.data.takeOff.background}>
                                    <TextField 
                                        value={props.submission.data.takeOff.value} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.takeOff.status === "error"} 
                                        variant = {props.submission.data.takeOff.variant}
                                        fullWidth 
                                        type = "time" 
                                        name = "takeOff" 
                                        id = "takeOff">
                                    </TextField>
                                </td>
                                <td className = "input" STYLE = {props.submission.data.landing.background}>
                                    <TextField 
                                        value={props.submission.data.landing.value} 
                                        onChange={e => props.TechLogHandleChange(e.target)} 
                                        error = {props.submission.data.landing.status === "error"} 
                                        variant = {props.submission.data.landing.variant} 
                                        fullWidth 
                                        type = "time" 
                                        name = "landing" 
                                        id = "landing">
                                    </TextField>
                                </td>    
                                <td><img src = {shortArrow} className = "shortArrow" alt = "Arrow"></img></td>
                                <td className = "calc" name="blockDifference" id="blockDifference">{props.blockDifferenceDisplay}</td>
                            </tr>
                            <tr>    
                                <td className="blank" colSpan="4"></td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
                <Box className = "arrival-table2-navigation-wide">
                    {/* <img className = "map" src = {map} alt = "Map"></img> */}
                    <ArrivalTable2Navigation 
                    TechLogHandleChange = {props.TechLogHandleChange} 
                    TechLogHandleChangeNumber = {props.TechLogHandleChangeNumber} 
                    submission = {props.submission}/>
                </Box>
                <Box>
                    <ArrivalTableDefects
                        TechLogHandleChange = {props.TechLogHandleChange} 
                        submission = {props.submission}/>
                </Box>
            </Box>
        )
}
