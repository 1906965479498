// Import libraries
// import {forwardRef, useState}  from 'react';
import React, {useEffect, useState} from 'react';
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/base/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // ***************************** MAIN FUNCTION ************************************
export default function FeedbackWithInput(props) {

  const [continueDisabled, setContinueDisabled] = useState(true);

  useEffect(() => {
    setContinueDisabled(!props.newApprovalDate || ! props.newAuthority);
  }, [props.newApprovalDate, props.newAuthority]);

  // Dialogue Close
  const handleCloseDialogue = (e, reason) => {
     
    if (e.name === "dialogueContinue") {
      props.UpdateDialogueStatusApproval(false);
        // console.log("In handleCloseDialogue, e.name->", e.name);
        // console.log("In handleCloseDialogue, props.dialogueStatusApproval->", props.dialogueStatusApproval);
      props.HandleContinueApproval(props.result);
    } else {
      if (reason === 'clickaway') {
        props.UpdateDialogueStatusApproval(false);
      }
      props.UpdateDialogueStatusApproval(false);
        // console.log("In handleCloseDialogue, props.dialogueStatusApproval->", props.dialogueStatusApproval);
    }
  };
 
  if (props.result) {   
    if (props.result.status !== "" && props.result.status !== undefined) {
      switch (props.result.status) {
        case "":
        case undefined:
            // console.log("In Feedback, switch case (undefined result.status) -> ", props.result.status)
          return;
        case "approval":
          // props.result.status = "SELECT AUTHORITY & DATE"
            // console.log("In Feedback, props.result->", props.result)
          return (
            <DialogueModel
              handleCloseDialogue = {handleCloseDialogue}
              continueDisabled = {continueDisabled}
              dialogueStatusApproval = {props.dialogueStatusApproval}
              title = "ADD APPROVAL"
              result = {props.result}
            />
          );
        default: console.log("Close Dialogue Box");
      } // SWITCH END
    } // IF 1 END
  } // IF 2 END
};

function DialogueModel(props) {
  return (
    <React.Fragment>
    <div>
      <Dialog
      open={props.dialogueStatusApproval}
      onClose={props.handleCloseDialogue}
      aria-labelledby="Warning"
      // aria-describedby={props.result.message}
      >
      <DialogTitle id="alert-dialog-title" sx={{background: "rgb(255, 248, 220)"}}>
        {props.title}
        </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx = {{fontWeight: "bold", fontColor: "rgb(0, 200, 255)", padding: "5px"}}>
              {props.result.message}
            </DialogContentText>
          </DialogContent>
        <DialogActions>
          <Button 
            name = "dialogueCancel" 
            variant = "outlined" 
            onClick={e => props.handleCloseDialogue(e.target)} autoFocus>
              Cancel
          </Button>
          <Button 
            name = "dialogueContinue" 
            variant = "contained" 
            disabled = {props.continueDisabled}
            onClick={e => props.handleCloseDialogue(e.target)}>
              Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </React.Fragment>
  );
}